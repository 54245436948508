import React from 'react'
import { List,
         Datagrid,
         TextField,
         DateField,
         useTranslate
} from 'react-admin'

// Components
import CustomCreateButton from "../../styles/CustomButtons/CustomCreateButton";

const DashboardsList = (props) => {
    const t = useTranslate();
    const label = t("dashboards.create.title")

    return (
    <List {...props} exporter={false} actions={<CustomCreateButton props={props} label={label}/>}>
        <Datagrid rowClick="show">
            <TextField source='name' />
            <DateField source='created_at' />
            <DateField source='updated_at' />
        </Datagrid>
    </List>
    )
}

export default DashboardsList;