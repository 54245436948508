// Lib
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EmailField,
  ChipField,
  useTranslate
} from "react-admin";

// Components
import CustomCreateButton from "../../styles/CustomButtons/CustomCreateButton";

const BuildingList = (props) => {
  const t = useTranslate();
  const label = t("users.create.title")

  return (
    <List {...props} actions={<CustomCreateButton props={props} label={label}/>}>
      <Datagrid rowClick="show">
        <TextField source="username" />
        <EmailField source="email" />
        <ChipField source="workspaces" />
        <ChipField source="role" />
        <BooleanField source="activated" />
      </Datagrid>
    </List>
  );
};

export default BuildingList;
