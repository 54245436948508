// Libs
import React from 'react';
// import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
// Context
import SelectButtons from './SelectButtons';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "5px 5px 0px 5px"
    },
    TitleContainer : {
        padding: "5px 5px 2px 5px",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize
    },
    ButtonsContainer : {
        borderRadius: "10px",
        border: "2px solid #E5E5E5",
        padding: "10px",
        width: "280px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center"}
}));

const AnalyticsFlowDirection = (props) => {
    const classes = useStyles();
    const t = useTranslate()

    const buttons = [
        {
            type: 'consumption',
            text: t("buildings.show.analytics.toolbar.flow_direction.consumption"),
        },
        {
            type: 'production',
            text: t("buildings.show.analytics.toolbar.flow_direction.production"),
        }
    ]

    return (
        <div className={classes.container}>
            <div className={classes.TitleContainer}>
                {props.title}
            </div>
            <div className={classes.ButtonsContainer}>
                <SelectButtons
                    buttons={buttons}
                    focusValue={props.value}
                    changeState={props.onChange}
                    orientation={"horizontal"}
                    borderColor={"#e89712"}
                />
            </div>
        </div>
    );
};


export default AnalyticsFlowDirection;