// in src/bitcoinSaga.js
import { call, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import axiosInstance from '../../utils/axiosInstance';


const getLoadCurve = (options, params) => {
    return axiosInstance.get(options, params)
}

const getActiveEnergy = (options, params) => {
    return axiosInstance.get(options, params)
}

const getMaximumPower = (options, params) => {
    return axiosInstance.get(options, params)
}


function* fetchLoadCurveConsumption (action) {
    try {
        yield put({type: 'GET_TIMESERIES_LOAD_CURVE_CONSUMPTION_PENDING'})
        const payload = yield call( getLoadCurve,
                                    'timeseries/load_curve/consumption',
                                    { params : action.params });
        yield put({type: 'GET_TIMESERIES_LOAD_CURVE_CONSUMPTION_SUCCEEDED', payload: payload})
    }
    catch {
        yield put({type: 'GET_TIMESERIES_LOAD_CURVE_CONSUMPTION_FAILED'})
    }
}

function* fetchLoadCurveProduction (action) {
    try {
        yield put({type: 'GET_TIMESERIES_LOAD_CURVE_PRODUCTION_PENDING'})
        const payload = yield call( getLoadCurve,
                                    'timeseries/load_curve/production',
                                    { params: {
                                        device_id: action.params.device_id,
                                        aggregate_period: action.params.aggregate_period,
                                        aggregate_type: action.params.aggregate_type,
                                        start_date: action.params.start_date,
                                        end_date: action.params.end_date
                                    }});
        yield put({type: 'GET_TIMESERIES_LOAD_CURVE_PRODUCTION_SUCCEEDED', payload: payload})
    }
    catch {
        yield put({type: 'GET_TIMESERIES_LOAD_CURVE_PRODUCTION_FAILED'})
    }
}

function* fetchActiveEnergy (action) {
    try {
        yield put({type: 'GET_TIMESERIES_ACTIVE_ENERGY_PENDING'})
        const payload = yield call( getActiveEnergy,
                                    'timeseries/active_energies/',
                                    { params: {
                                        device_id: action.params.device_id,
                                        aggregate_period: action.params.aggregate_period,
                                        aggregate_type: action.params.aggregate_type,
                                        start_date: action.params.start_date,
                                        end_date: action.params.end_date
                                    }});
        yield put({type: 'GET_TIMESERIES_ACTIVE_ENERGY_SUCCEEDED', payload: payload})
    }
    catch {
        yield put({type: 'GET_TIMESERIES_ACTIVE_ENERGY_FAILED'})
    }
}

function* fetchMaximumPower (action) {
    try {
        yield put({type: 'GET_TIMESERIES_MAXIMUM_POWER_PENDING'})
        const payload = yield call( getMaximumPower,
                                    'timeseries/maximum_powers',
                                    { params: {
                                        device_id: action.params.device_id,
                                        aggregate_period: action.params.aggregate_period,
                                        aggregate_type: action.params.aggregate_type,
                                        start_date: action.params.start_date,
                                        end_date: action.params.end_date
                                    }});
        yield put({type: 'GET_TIMESERIES_MAXIMUM_POWER_SUCCEEDED', payload: payload})
    }
    catch {
        yield put({type: 'GET_TIMESERIES_MAXIMUM_POWER_FAILED'})
    }
}


function* clearTimeseries (action) {
    yield put({type: 'CLEAR_TIMESERIES_PENDING'})
    yield put({type: 'CLEAR_TIMESERIES_SUCCEEDED'})
}

export default function* timeseriesSaga() {
    yield takeEvery('GET_TIMESERIES_LOAD_CURVE_CONSUMPTION', fetchLoadCurveConsumption);
    yield takeEvery('GET_TIMESERIES_LOAD_CURVE_PRODUCTION', fetchLoadCurveProduction);
    yield takeEvery('GET_TIMESERIES_MAXIMUM_POWER', fetchMaximumPower);
    yield takeEvery('GET_TIMESERIES_ACTIVE_ENERGY', fetchActiveEnergy);
    yield takeEvery('CLEAR_TIMESERIES', clearTimeseries);
}
