// Libs
import React, { useState, useEffect } from "react";
import {
  useTranslate,
  FilterForm,
  FilterButton,
  TextInput,
  AutocompleteInput,
} from "react-admin";
import { Stack } from "@mui/material";

// Components
// import CustomCreateButton from "../../../styles/CustomButtons/CustomCreateButton";

// DataProvider
import CustomDataProvider from "../../../dataProvider";

const DeviceListActions = (props) => {
  const t = useTranslate();
  // const label = t("devices.title.create")

  const [buildings, setBuildings] = useState();

  useEffect(() => {
    CustomDataProvider.getMany("buildings", {}).then((data) =>
      setBuildings(data.data)
    );
  }, []);

  const deviceFilters = [
    <TextInput source="name" />,
    <AutocompleteInput
      source="type"
      choices={[
        { id: "gas_meter", name: `${t("devices.name.gas")}` },
        { id: "electricity_meter", name: `${t("devices.name.electricity")}` },
      ]}
    />,
    <AutocompleteInput source="parent" choices={buildings ? buildings : []} />,
  ];

  return (
    <div style={{ width: "100%" }}>
      <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={deviceFilters} />
        <div>
          <FilterButton filters={deviceFilters} />
          {/* <CustomCreateButton props={props} label={label}/> */}
        </div>
      </Stack>
    </div>
  );
};

export default DeviceListActions;
