const translateAnalyticsFr = {
  analytics: {
    types: {
      title: "Type d'analyse",
      active_power: "Courbe de charge",
      active_energy: {
        title: "Energie active",
        grid_type: {
          operator: "Distributeur",
          supplier: "Fournisseur",
        },
      },
      maximum_power: {
        title: "Puissance maximale",
      },
    },
  },
};

export default translateAnalyticsFr;
