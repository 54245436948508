// Libs
import React, { useState, useEffect } from "react";
import { useFormState } from "react-final-form";
import {
  TextInput,
  SelectInput,
  SelectArrayInput,
  BooleanInput,
  useTranslate,
} from "react-admin";

// Material UI components
import { Typography } from "@material-ui/core";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

// DataProvider
import CustomDataProvider from "../../dataProvider";

const AlertForm = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const formState = useFormState();

  const [buildings, setBuildings] = useState([]);
  const [devices, setDevices] = useState([]);
  const [customDevices, setCustomDevices] = useState("");
  const [choosenDevices, setChoosenDevices] = useState();
  const [deviceProperties, setDeviceProperties] = useState();
  const [users, setUsers] = useState();

  // Get buildings, devices and users from dataProvider
  useEffect(() => {
    CustomDataProvider.getMany("buildings", {}).then((data) =>
      setBuildings(data.data)
    );
    CustomDataProvider.getMany("devices", {}).then((data) =>
    setDevices(data.data)
    );

    CustomDataProvider.getMany("users", {}).then((data) => {
      setUsers(data.data.map((user) => ({ id: user.id, name: user.username })));
    });
  }, []);

  // Set devices informations form with building parent name
  useEffect(() => {
    if (devices) {
      setCustomDevices(devices.map((device) => ({
          id: device.id,
          name: `${device.name} - ${
            buildings.find((building) => building.id === device.parent).name
          }`,
          properties: device.properties,
        }))
      );
  }
  }, [devices, buildings]);

  // Get device informations when selected in form
  useEffect(() => {
    if (formState.values.check) {
      const selectedDeviceId = [formState.values.check.device];
      let selectedDevices = [];

      for (let i = 0; i < selectedDeviceId.length; i++) {
        for (let j = 0; j < devices.length; j++) {
          if (selectedDeviceId[i] === devices[j].id) {
            selectedDevices.push(devices[j]);
          }
        }
      }
      setChoosenDevices(selectedDevices);
    }

    if (choosenDevices) {
      const propertiesDeviceArray = choosenDevices.map(
        (device) => device.properties
      );
      const propertiesDevice = [];
      propertiesDeviceArray.forEach((properties) =>
        properties.forEach((property) => propertiesDevice.push(property))
      );

      setDeviceProperties(propertiesDevice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values, devices]);

  // Concat properties of selected devices in form
  useEffect(() => {
    if (choosenDevices) {
      const propertiesDeviceArray = choosenDevices.map(
        (device) => device.properties
      );
      const propertiesDevice = [];
      propertiesDeviceArray.forEach((properties) =>
        properties.forEach((property) => propertiesDevice.push(property))
      );

      setDeviceProperties(propertiesDevice);
    }
  }, [choosenDevices, devices, formState.values]);

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput
              required
              source="name"
              helperText={false}
              label={t("alerts.general.title")}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <BooleanInput
              required
              source="is_active"
              helperText={false}
              label="Alerte activée"
              defaultValue={true}
            />
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">{t("alerts.data.title")}</Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <SelectInput
              required
              source="check.device"
              helperText={false}
              label={t("alerts.data.device")}
              choices={customDevices ? customDevices : []}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <SelectInput
              required
              disabled={deviceProperties ? false : true}
              source="check.property"
              helperText={false}
              label={t("alerts.data.type")}
              choices={deviceProperties ? deviceProperties : []}
            />
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">{t("alerts.trigger.title")}</Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <SelectInput
              required
              source="check.strategy"
              helperText={false}
              label={t("alerts.trigger.type")}
              choices={[
                {
                  id: "superior",
                  name: t("alerts.form.trigger.strategy.superior"),
                },
                {
                  id: "inferior",
                  name: t("alerts.form.trigger.strategy.inferior"),
                },
                { id: "equal", name: t("alerts.form.trigger.strategy.equal") },
              ]}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput
              required
              source="check.value_threshold"
              helperText={false}
              label={t("alerts.trigger.value")}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <SelectInput
              required
              source="check.interval_in_minutes"
              helperText={false}
              label={t("alerts.trigger.check")}
              choices={[
                {
                  id: 1,
                  name: t("alerts.form.interval_in_minutes.each_minute"),
                },
                {
                  id: 5,
                  name: t("alerts.form.interval_in_minutes.each_5_minutes"),
                },
                {
                  id: 15,
                  name: t("alerts.form.interval_in_minutes.each_15_minutes"),
                },
                {
                  id: 30,
                  name: t("alerts.form.interval_in_minutes.each_30_minutes"),
                },
                {
                  id: 60,
                  name: t("alerts.form.interval_in_minutes.each_hour"),
                },
                {
                  id: 360,
                  name: t("alerts.form.interval_in_minutes.each_6_hours"),
                },
                {
                  id: 720,
                  name: t("alerts.form.interval_in_minutes.each_12_hours"),
                },
                {
                  id: 1440,
                  name: t("alerts.form.interval_in_minutes.each_day"),
                },
              ]}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <SelectInput
              required
              source="notification.reminder_interval_in_minutes"
              helperText={false}
              label={t("alerts.trigger.reminder")}
              choices={[
                {
                  id: 15,
                  name: t("alerts.form.interval_in_minutes.each_15_minutes"),
                },
                {
                  id: 30,
                  name: t("alerts.form.interval_in_minutes.each_30_minutes"),
                },
                {
                  id: 60,
                  name: t("alerts.form.interval_in_minutes.each_hour"),
                },
                {
                  id: 360,
                  name: t("alerts.form.interval_in_minutes.each_6_hours"),
                },
                {
                  id: 720,
                  name: t("alerts.form.interval_in_minutes.each_12_hours"),
                },
                {
                  id: 1440,
                  name: t("alerts.form.interval_in_minutes.each_day"),
                },
                {
                  id: 10080,
                  name: t("alerts.form.interval_in_minutes.each_week"),
                },
                {
                  id: 43200,
                  name: t("alerts.form.interval_in_minutes.each_month"),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">{t("alerts.notification.title")}</Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <SelectArrayInput
              required
              source="notification.users"
              helperText={false}
              label={t("alerts.notification.user")}
              choices={users}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <SelectArrayInput
              required
              source="notification.strategies"
              helperText={false}
              label={t("alerts.notification.type")}
              choices={[
                {
                  id: "sms",
                  name: t("alerts.form.notification.type.phone_text"),
                },
                { id: "email", name: t("alerts.form.notification.type.email") },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertForm;
