// Libs
import React from "react";
import {
  TextInput,
  BooleanInput,
  SelectInput,
  email,
  PasswordInput,
  useTranslate,
} from "react-admin";
import { useField } from "react-final-form";

// Material UI components
import { Typography } from "@material-ui/core";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

// React Phone Input Components
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const PhoneNumberInput = ({ name, label }) => {
  const globalClasses = globalUseStyles();
  const {
    input: { onChange },
    meta: { touched, error },
    isRequired,
  } = useField(name);

  return (
    <PhoneInput
      country={"fr"}
      name={name}
      label={label}
      onChange={onChange}
      containerClass={globalClasses.customPhoneContainer}
      inputClass={globalClasses.customPhoneInput}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
    />
  );
};

const UserCreateForm = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const validateEmail = email();

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput
              required
              source="username"
              label={t("users.show.username")}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <SelectInput
              source="role"
              required
              label={t("users.show.role")}
              choices={[
                { id: "admin", name: "admin" },
                { id: "user", name: "user" },
              ]}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <PhoneNumberInput
              name="phone_number"
              label={t("users.show.phonenumber")}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput
              source="email"
              label={t("users.show.mail")}
              required
              validate={validateEmail}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <PasswordInput
              source="password"
              label={t("users.show.password")}
              required
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <BooleanInput
              source="activated"
              label={t("users.show.activated")}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreateForm;
