// Libs
import { CreateButton, usePermissions } from "react-admin";

const CustomCreateButton = (props) => {
  const { permissions } = usePermissions();

  return (
    <>
      {permissions === "admin" && (
        <CreateButton
          label={props.label} //label
          basePath={props.basePath} //basePath
        />
      )}
    </>
  );
};

export default CustomCreateButton;
