// lib
import React, { useRef, useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

//Mapbox
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../index.css';

// components
import InputField from "./InputField";

// const MAP_BOX_KEY = process.env.REACT_APP_MAP_BOX_KEY;
const MAP_BOX_KEY = "pk.eyJ1IjoidGJpYXJuZWl4IiwiYSI6ImNsMG1meGM3MjBhNmUzY250ZWZrcnFuaTEifQ.aL3eO-ZeH_V8-xLdQALrjw";
mapboxgl.accessToken = MAP_BOX_KEY;


const BuildingMap = (props) => {
  const { values } = useFormState();
  const form = useForm();

  // mapbox creation
  const mapContainer = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map(
      {
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [
          values.longitude ? values.longitude : 1.4437,
          values.latitude ? values.latitude : 43.6043,
        ],
        zoom: 16,
      },
      []
    );
    new mapboxgl.Marker()
      .setLngLat([
        values.longitude ? values.longitude : 1.4437,
        values.latitude ? values.latitude : 43.6043,
      ])
      .addTo(map);
    return () => map.remove();
  }, [props.value, values.latitude, values.longitude]);

  const handleChange = (location) => {
    form.change("longitude", location.longitude);
    form.change("latitude", location.latitude);
    form.change("city", location.city);
    form.change("country", location.country);
    form.change("address", location.address);
    form.change("zipcode", location.zipcode);
    form.change("full_address", location.fullAddress);
    return props.onChange(location.fullAddress);
  };

  return (
    <div>
      <Field name="latitude" initialValue={43.6043} component={() => null} />
      <Field name="longitude" initialValue={1.4437} component={() => null} />
      <Field name="city" component={() => null} />
      <Field name="country" component={() => null} />
      <Field name="zipcode" component={() => null} />
      <InputField
        value={props.value ? props.value : values.full_address ? values.full_address : ""}
        onChange={(location) => handleChange(location)}
      />
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default BuildingMap;
