import englishMessages from 'ra-language-english';


const customEnglishMessages = { ...englishMessages,
    dashboard: {
        last_month_consumption : "Last month consumption",
        anomalies : "Anomalies",
        contrat_reviews : "Contracts review",
        building : "Buildings",
        last_month_billing: "Last month billing",
    },
    resources : {
        building : {
             name: "Buildings",
             fields : {
                name : "Name",
                surface : "Surface",
                address : "Address",
                city : "City",
                country : "Country",
             },
        },
        anomalies : {
            name: "Building",
            viewed: "Viewed",
            timestamp: "Created At"
        },
        report : {
            name: "Raport"
        },
        contract : {
            name: "Contract"
        },
        billing : {
            name: "Billing"
        },
        users : {
            name: "Users",
            fields : {
                username : "Username",
                email: "Email",
                workspaces: "Workspaces",
                roles: "Roles",
                activated: "Active"
            }
        },
        dashboards: {
            show: {
                title: "Show dashboards"
            },
            chart_type: {
                chart_column_bar: "Column",
                chart_horizontal_bar: "Horizontal bars",
                chart_line: "Lines",
                number: "Number",
                map: "Map"
            },
            add_graph: "Add Chart",
            server_error: "Server error",
            graph: {
                aggregate_type: "Type d'aggregation",
                aggregate_period: "Période d'aggregation",
                graph_type: "Type de graphique",
                period_type: "Type de période",
                aggregate_operation: "Opération d'aggrégation",
                aggregate_category: "Catégorie d'aggrégration",
                aggregate_value: "Value",
                device: "Device",
                properties: "Propriété",
                title: "Title",
                data_source: "Data source",
                add_source: "+ Add source",
                period_types: {
                    selected: "Selected period",
                    weeks: "Last week(s)"
                },
                aggregation: {
                    period: {
                        "raw": "Raw",
                        "hour": "Hour",
                        "day": "Day",
                        "week": "Week",
                        "month": "Month",
                        "year": "Year"
                    },
                    type: {
                        "raw": "Raw",
                        "average": "Average",
                        "sum": "Sum"
                    },
                    operation: {
                        "none": "/",
                        "weather": "Weather"
                    },
                    category: {
                        "none": "None",
                        "arithmetic": "Arithmetic",
                        "functional": "Functional",
                    },
                }
            }
        }
    }
}

export default customEnglishMessages;