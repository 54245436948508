// Libs
import React, { useState, useEffect } from "react";
import { Title, useTranslate } from "react-admin";

// MUI components
import { makeStyles } from "@material-ui/core/styles";

// Components
import BuildingsMap from "./map/BuildingsMap";

// Dataprovider
import CustomDataProvider from "../../dataProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  globalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "auto",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  selfContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    margin: "0.5rem",
    padding: "1.2rem 0 1.9rem ",
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33",
  },
  customNumber: {
    fontSize: "2.5em",
    color: "#E89712",
    fontWeight: "bold",
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
  customText: {
    fontSize: "1.5em",
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
  mapContainer: {
    height: "550px",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const t = useTranslate();

  const [buildings, setBuildings] = useState([]);
  const [devices, setDevices] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [dashboards, setDashboards] = useState([]);

  useEffect(() => {
    CustomDataProvider.getMany("buildings", {}).then((data) => {
      setBuildings(data.data);
    });
    CustomDataProvider.getMany("devices", {}).then((data) => {
      setDevices(data.data);
    });
    CustomDataProvider.getMany("alerts", {}).then((data) => {
      setAlerts(data.data);
    });
    // CustomDataProvider.getMany("dashboards", {}).then((data) => {
    //   setDashboards(data.data);
    // });
  }, []);

  return (
    <div className={classes.root}>
      <Title title={t("home.name")} />
      <div className={classes.globalContainer}>
        <div className={classes.subContainer}>
          <div className={classes.selfContainer}>
            <p className={classes.customNumber}>{`${buildings.length}`}</p>
            <p className={classes.customText}>{t("home.buildings")}</p>
          </div>
          <div className={classes.selfContainer}>
            <p className={classes.customNumber}>{`${devices.length}`}</p>
            <p className={classes.customText}>{t("home.devices")}</p>
          </div>
        </div>
        <div className={classes.subContainer}>
          <div className={classes.selfContainer}>
            <p className={classes.customNumber}>{`${alerts.length}`}</p>
            <p className={classes.customText}>{t("home.alerts")}</p>
          </div>
          <div className={classes.selfContainer}>
            <p className={classes.customNumber}>{`${dashboards.length}`}</p>
            <p className={classes.customText}>{t("home.dashboards")}</p>
          </div>
        </div>
        <div className={classes.mapContainer}>
          <BuildingsMap />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
