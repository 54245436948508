// Libs
import React from 'react';
import { useState, useEffect } from 'react';
// import { useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { format } from 'date-fns'
// Components
import CalendarSelector from "./CalendarSelector"


const useStyles = makeStyles((theme) => ({
    container: {
        padding: "5px 5px 0px 5px"
    },
    TitleContainer : {
        padding: "5px 5px 2px 5px",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize
    },
    CalendarsContainer : {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
        borderRadius: "10px",
        border: "2px solid #E5E5E5",
        padding: "10px",
        width: "200px",
        height: "40px",
    },
    Calendar :
    {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
    }
}));


const AnalyticsPeriodRange = (props) => {
    const classes = useStyles();
    const startDate = props.value[0];
    const endDate = props.value[1];


    const handleStartDate = value => {
        let dates = [value, endDate];

        if( value > endDate ){
            dates[1] = value
        }
        props.onChange(dates)
    }

    const handleEndDate = value => {
        let dates = [startDate, value];

        if( startDate > value ){
            dates[0] = value
        }
        props.onChange(dates)
    }

    return (
        <div className={classes.container}>
            <div className={classes.TitleContainer}>
                {props.title}
            </div>
            <div className={classes.CalendarsContainer}>
                <div className={classes.Calendar}>
                    <CalendarSelector
                        value={startDate}
                        title={format(startDate, "dd MMM yyyy")}
                        handleCalendarChange={val => handleStartDate(val)}
                    />
                </div>
                <div className={classes.Calendar}>
                    <CalendarSelector
                        value={endDate}
                        title={format(endDate, "dd MMM yyyy")}
                        handleCalendarChange={val => handleEndDate(val)}
                    />
                </div>
            </div>
        </div>
    );
};


export default AnalyticsPeriodRange;