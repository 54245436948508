// Libs
import React from "react";
import {
  TextField,
  ReferenceField,
  ArrayField,
  Datagrid,
  useTranslate,
  useRecordContext,
} from "react-admin";

// Material UI components
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

// Custom list style
const useStyles = makeStyles({
  headerCell: {
    color: "#000 !important",
  },
});

// Display device type as words
const DeviceType = () => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const t = useTranslate();

  if (record.type === "electricity_meter") {
    return (
      <span className={globalClasses.customTextField}>
        {t("devices.name.electricity")}
      </span>
    );
  } else if (record.type === "gas_meter") {
    return (
      <span className={globalClasses.customTextField}>
        {t("devices.name.gas")}
      </span>
    );
  } else if (record.type === "water_meter") {
    return (
      <span className={globalClasses.customTextField}>
        {t("devices.name.water")}
      </span>
    );
  } else if (record.type === "sensor") {
    return (
      <span className={globalClasses.customTextField}>
        {t("devices.name.sensor")}
      </span>
    );
  } else {
    return (
      <span className={globalClasses.customTextField}>
        {t("devices.name.undefined")}
      </span>
    );
  }
};

const DeviceShowDetails = () => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("devices.list.name")}</Typography>
            <TextField
              source="name"
              className={globalClasses.customTextField}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("devices.list.building")}</Typography>
            <ReferenceField
              source="parent"
              reference="buildings"
              className={globalClasses.customTextField}
            >
              <TextField
                source="name"
                className={globalClasses.customBoldTextField}
              />
            </ReferenceField>
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("devices.list.type")}</Typography>
            <DeviceType />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("devices.list.properties")}</Typography>
            <div className={globalClasses.customArrayField}>
              <ArrayField source="properties">
                <Datagrid classes={classes} >
                  <TextField source="name" />
                  <TextField source="value" />
                </Datagrid>
              </ArrayField>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceShowDetails;
