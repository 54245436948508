import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  logo: {
    width: "2rem",
    margin: 10,
  },
});

const Logo = () => {
  const classes = useStyles();
  return (
    <img src="/VoltyoLogo.png" alt="Logo Voltyo" className={classes.logo}/>
  );
};

export default Logo;
