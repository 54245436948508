import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ReactECharts from 'echarts-for-react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    chartLine: {
        height: "100%",
        width: "100%"
    }
}));

var formatTooltipLine = function(color, text){
    return "<span style='display:inline-block;width:10px;height:10px;border-radius:50%;background-color:"+color+";margin-right:5px;'></span><span><b>"+text+"<b></span>"
}


const getWeekendMarkAreas = ( timestamps ) => {
    const uniqueTimestamps = [...new Set(timestamps)]; // Get unique timestamps
    const MaxTimestamp =  new Date(Math.max(...uniqueTimestamps))
    const MinTimestamp =  new Date(Math.min(...uniqueTimestamps))

    if ( MinTimestamp.getDay() === 0 ) {
        MinTimestamp.setDate(MinTimestamp.getDate() - 1)
    }
    if ( MaxTimestamp.getDay() === 6 ) {
        MaxTimestamp.setDate(MaxTimestamp.getDate() + 1 )
    }

    let weekendMarkArea = [];
    let weekends = [];
    for ( let ts = MinTimestamp; ts <= MaxTimestamp; ts.setDate(ts.getDate() + 1) ) {
        if ( ts.getDay() === 6 ) {
            weekends.push(new Date(ts.setHours(0, 0, 0 , 0)));
        }
        if ( ts.getDay() === 0 ) {
            weekends.push(new Date(ts.setHours(24, 0, 0, 0)));

            weekendMarkArea.push([
                {
                    xAxis: weekends[0],
                    itemStyle: { color: '#FDDA0D80'}
                }, {
                    xAxis: weekends[1],
                    itemStyle: { color: '#FDDA0D80'}
                 }
            ]);
            weekends = []
        }
    }
    return weekendMarkArea
}

const getNightsMarkAreas = ( timestamps ) => {

    const uniqueTimestamps = [...new Set(timestamps)]; // Get unique timestamps
    const MaxTimestamp =  new Date(Math.max(...uniqueTimestamps))
    const MinTimestamp =  new Date(Math.min(...uniqueTimestamps))

    let nightsMarkArea = [];
    let nights = [];
    for ( let ts = MinTimestamp; ts <= MaxTimestamp; ts.setDate(ts.getDate() + 1) ) {

        let ts_tmp = new Date(ts)
        nights.push(new Date(ts_tmp.setHours(20, 0, 0, 0)));
        nights.push(new Date(ts_tmp.setHours(32, 0, 0, 0)));

        nightsMarkArea.push([
            {
                xAxis: nights[0],
                itemStyle: { color: '#ADBAE180'}
            }, {
                xAxis: nights[1],
                itemStyle: { color: '#ADBAE180'}
            }
        ]);
        nights = []
    }
    return nightsMarkArea
}


const ChartLine = (props) => {
    const t = useTranslate();
    const classes = useStyles();
    const series_set = props.data.map( v => v.series );
    const ids_set = props.data.map( v => v.device_id );
    const colors_set = props.data.map( v=> v.color)
    const name_set = props.data.map( v => v.name );
    const units_set = props.data.map ( v => v.unit );


    let timestamps_arrays = []
    let timestamps = []
    if ( props.options.isNightsHighlight || props.options.isWeekendsHighlight) {
        timestamps_arrays = series_set.map( s => s.map( c => new Date(c.timestamp) ) );
        timestamps = [].concat.apply([], timestamps_arrays);
    }

    const weekendMarkAreas = props.options.isWeekendsHighlight ? getWeekendMarkAreas(timestamps) : [];
    const nightMarkAreas = props.options.isNightsHighlight ? getNightsMarkAreas(timestamps) : [];

    let series = series_set.map( (curve, index) => {
        const data = curve.map(v => [v.timestamp, v.value] );
        return {
            name: name_set[index],
            type: 'line',
            smooth: true,
            showSymbol: false,
            itemStyle: {
            color: colors_set[index]},
            data: data,
            markArea: {
                data: index === 0 ?  [...weekendMarkAreas, ...nightMarkAreas] : [],
            }
        }
    });

    if ( series.length === 0 ) {
        series = { type: 'line',
                   data : [ ] }
    }

    const option = {
            title: {
                top: 0,
                left: 'center',
                text:  props.title,
                textStyle: {
                    fontSize: 18,
                    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
                },
            },
            tooltip: {
              trigger: 'axis',
               formatter: function (params) {
                     return `${new Date(params[0].axisValueLabel).toLocaleDateString("fr")} ${new Date(params[0].axisValueLabel).toLocaleTimeString("fr")}<br />
                             ${params.map( item => formatTooltipLine(item.color, item.seriesName + ": " + item.value[1] + " " + units_set[item.seriesIndex])).join("<br/>")}`
                }
            },
            legend: {
                type: 'scroll',
                top: '10%',
                // bottom: 0,
                // top: '13%',
                // right: '1%',
                // orient: 'vertical',
              data: name_set,
              textStyle: {
                fontSize: 12,
                fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
              },
            },
            grid: {
                top: '20%',
                left: '2%',
                right: '2%',
                bottom: '15%',
                containLabel: true
            },
            toolbox: {
              feature: {
                dataZoom: {
                  brushStyle: {
                    color: '#41445D',
                    opacity: 0.2
                  },
                },
                restore: {},
                saveAsImage: {},
                xAxisIndex: {},
                yAxisIndex: {},
                dataView: {}
              }
            },
            xAxis: {
              type: 'time',
              boundaryGap: false
            },
            yAxis: {
                name: 'Consommation ('+ units_set[0] + ')',
                type: 'value',
                nameLocation: 'middle',
                nameGap: 40
            },
            dataZoom: [
              {
                type: 'slider',
                start: 0,
                end: 100
              }
            ],
            series: series
          };

    return (
        <div className={classes.chartLine}>
            <ReactECharts 
                option={option}
                notMerge={true}
                style={{height: '100%', width: '100%'}} />
        </div>
    )
}

export default ChartLine
