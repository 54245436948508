// lib
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";

import globalUseStyles from "../../../styles/globalCustomStyles";

// Multistepform custom design
const useStyles = makeStyles((theme) => ({
  root: {
    width: "85%",
    margin: "auto",
    paddingBottom: "0.3rem",
    minHeight: "35rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiStepIcon-active": { color: "#E89712" },
    "& .MuiStepIcon-completed": { color: "#E89712" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#eaeaf0" },
    "& .MuiStepIcon-text": { color: "#E89712" },
    [theme.breakpoints.down(980)]: {
      width: '95%',
    },
  },
  labelContainer: {
    display: "none",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
}));

// Stepper connector design
const Connector = withStyles({
  alternativeLabel: {
    top: 11,
    left: "calc(-50% + 12px)",
    right: "calc(50% + 12px)",
  },
  active: {
    "& $line": {
      borderColor: "#E89712",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#E89712",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
    margin: 0,
  },
})(StepConnector);



// Multistep form operation
const StepsComponents = (props) => {
  const globalClasses = globalUseStyles();
  const classes = useStyles();

  return (
    <div className={globalClasses.buildingsFormContainer}>
      <div className={classes.root}>
        <div>
          <Stepper
            activeStep={props.activeStep}
            connector={<Connector />}
            alternativeLabel
          >
            {props.steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {
            <div className={classes.root}>
              {props.getStepContent(props.activeStep)}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default StepsComponents;
