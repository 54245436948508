// lib
import { useState } from "react";
import { useTranslate } from "react-admin";
import { TextField } from "@material-ui/core";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoidGJpYXJuZWl4IiwiYSI6ImNsMG1meGM3MjBhNmUzY250ZWZrcnFuaTEifQ.aL3eO-ZeH_V8-xLdQALrjw";

const InputField = (props) => {
  // React-admin variable
  const t = useTranslate();
  const [value, setValue] = useState(props.value ? props.value : "");
  const [suggestions, setSuggestions] = useState([]);

  const UserInput = () => {
    const handleChange = async (event) => {
      setValue(event.target.value);
      try {
        const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true`;
        const response = await fetch(endpoint);
        const results = await response.json();
        setSuggestions(results?.features);
      } catch (error) {
        console.error("Error fetching data, ", error);
      }
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  return (
    <div>
      <TextField
        required={true}
        label={t("buildings.create.section.address")}
        source="address"
        placeholder={t("buildings.create.section.address")}
        {...UserInput("")}
        istyping={value ? (value !== "").toString() : undefined}
        fullWidth={true}
        variant="filled"
      />
      {suggestions?.length > 0 && (
        <div
          style={{
            position: "absolute",
            zIndex: "3",
            width: "50%",
            backgroundColor: "white",
          }}
        >
          {suggestions.map((suggestion, index) => {
            const setLocationInformation = () => {
              const locationContextInformations = suggestion.context;
              let location = {
                city: locationContextInformations.filter((infos) =>
                  infos.id.includes("place")
                )[0].text,
                country: locationContextInformations.filter((infos) =>
                  infos.id.includes("country")
                )[0].text,
                longitude: suggestion.geometry.coordinates[0],
                latitude: suggestion.geometry.coordinates[1],
                fullAddress: suggestion.place_name,
                address: `${suggestion.address} ${suggestion.text}`,
              };

              for (let i = 0; i < locationContextInformations.length; i++) {
                if (locationContextInformations[i].id.includes("postcode")) {
                  location.zipcode = locationContextInformations.filter(
                    (infos) => infos.id.includes("postcode")
                  )[0].text;
                  break;
                } else {
                  location.zipcode = null;
                }
              }
              props.onChange(location);
            };

            return (
              <p
                key={index}
                onClick={() => {
                  setValue(suggestion.place_name);
                  setSuggestions([]);
                  setLocationInformation();
                }}
              >
                {suggestion.place_name}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InputField;
