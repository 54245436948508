import { Layout } from "react-admin";
import CustomAppBar from "./CustomAppBar";
import { makeStyles } from "@material-ui/core/styles";
// import MySidebar from './MySidebar';
// import MyMenu from './MyMenu';
// import MyNotification from "./MyNotification";

const useStyles = makeStyles((theme) => ({
  appFrame: {
    marginTop: "1rem",
    [theme.breakpoints.down(980)]: {
      paddingRight: "1em",
    },
  },
}));

const CustomLayout = (props) => {
  const classes = useStyles();
  return (
    <Layout
      {...props}
      className={classes.appFrame}
      appBar={CustomAppBar}
    />
  );
};

export default CustomLayout;
