import { useState, useEffect } from 'react'
import AnalyticsCard from "./AnalyticsCard";
import { getDeviceIds, getInitialPeriodRange, getPropertyId, toTimestamp } from '../../helpers/helper';
import { useTranslate } from 'react-admin';
import { getTimeseriesData } from '../timeseries/helpers';



const AnalyticsActiveEnergyCard = (props) => {
    const t = useTranslate()
    const [periodAggregate, setPeriodAggregate] = useState('month');
    const [resource, setResource] = useState('electricity');
    const [surfaceCorrection, setSurfaceCorrection] = useState(false)
    const [weatherCorrection, setWeatherCorrection] = useState(false)
    const [periodRange, setPeriodRange] = useState(getInitialPeriodRange(periodAggregate))
    const [deviceIds,setDeviceIds] = useState(getDeviceIds(props.buildings, 'electricity_meter'))
    const [energyFlowDirection, setEnergyFlowDirection] = useState('consumption')
    const [toggleRender, setToggleRender] = useState(true)
    const [graphData, setGraphData] = useState([])

    useEffect(() => {
        const initial = getInitialPeriodRange(periodAggregate)
        const _ = require('lodash')
        if (_.isEqual(periodRange,initial) === false){
            setPeriodRange(getInitialPeriodRange(periodAggregate))
        }
    }, [periodAggregate])

    // Check whether the deviceIds are the same
    useEffect(() => {
        const _ = require('lodash')
        const newDeviceIds = getDeviceIds(props.buildings, resource + '_meter')
        if (_.isEqual(deviceIds,newDeviceIds) === false){
            setDeviceIds(newDeviceIds)
        }
    }, [props.buildings, resource, deviceIds])

    // Make API calls
    useEffect(() => {
        setGraphData([])
        const sources = deviceIds.map( (item) => {
            return {
                    type: 'device',
                    id: item.id,
                    property: getPropertyId(item, "active_energy")
                }
            // Apply arithmethic currections: Surface or Weather
        });

        if (sources.length > 0) {
            const moment = require('moment')
            const params = {
                    aggregation_period: periodAggregate,
                    aggregation_type: 'raw',
                    start_date: moment(periodRange[0]).format(),
                    end_date: moment(periodRange[1]).format(),
                    sources: sources
                }
            getTimeseriesData(params).then((data) => {
                setGraphData(data?.data?.map((d) => {
                    d.series.sort(function(x, y){
                        return toTimestamp(x.timestamp) - toTimestamp(y.timestamp);
                    })
                    return ({...d, series: d.series.map((serie, idx) => {
                            return ({ timestamp: serie.timestamp, value: serie.value });
                        })
                    })
                }));
            });
        }
    }, [periodRange, periodAggregate, deviceIds, weatherCorrection, surfaceCorrection, energyFlowDirection]);

    useEffect(() => {
        graphData?.map( (item) => {
            const device = deviceIds.filter( device => device.id === item.device_id)[0]
            const building = props.buildings.filter( building => building.id === device?.parent)[0]
            let opacity = 'FF'
            item.name = building?.name

            if (item.property_name.includes("operator") ){
                item.name = item.name?.concat(" - ", "Distributeur")
                opacity = 175
            }
            if (item.property_name.includes("supplier") ){
                item.name = item.name?.concat(" - ", "Fournisseur")
                opacity = 100
            }

            if (item.property_name.includes("summer") ){
                item.name = item.name?.concat(" - ", 'Été')
                opacity += 25
            }
            else if (item.property_name.includes("winter") ){
                item.name = item.name?.concat(" - ", 'Hiver')
                opacity -= 25
            }

            if (item.property_name.includes("off_peak") ){
                item.name = item.name?.concat(" - ", 'Heures Creuses')
                opacity -= 50
            }
            else if (item.property_name.includes("peak") ){
                item.name = item.name?.concat(" - ", 'Heures Pleines')
                opacity += 50
            }
            else if (item.property_name.includes("base") ){
                item.name = item.name?.concat(" - ", 'Base')
            }

            item.color = building?.color + opacity.toString(16)
        })
        setToggleRender(!toggleRender)
    }, [graphData, props.gridType])

    return <AnalyticsCard
        chartType={'ChartColumn'}
        buildings={props.buildings}
        energyFlowDirection={energyFlowDirection}
        onChangeEnergyFlowDirection={setEnergyFlowDirection}
        resource={resource}
        onChangeResource={setResource}
        periodAggregate={periodAggregate}
        periodAggregateDisabled={['hour', 'day']}
        onChangePeriodAggregate={setPeriodAggregate}
        periodRange={periodRange}
        onChangePeriodRange={setPeriodRange}
        surfaceCorrection={surfaceCorrection}
        onChangeSurfaceCorrection={setSurfaceCorrection}
        weatherCorrection={weatherCorrection}
        onChangeWeatherCorrection={setWeatherCorrection}
        chartTitle={t('analytics.types.active_energy.title')}
        timeseries={graphData}/>

}

export default AnalyticsActiveEnergyCard;