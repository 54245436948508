import restProvider from 'ra-data-simple-rest'
import {fetchUtils} from 'react-admin'
import AuthProvider from './AwsCognitoAuthProvider';

const httpClient = (async (url, options = {}) => {

  // await authProvider.checkRefresh()

  // const access_token = JSON.parse(localStorage.getItem('access_token'));
  const cognito_session = await AuthProvider.checkAuth()

  const access_token = cognito_session.getAccessToken().getJwtToken()

  if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
  options.headers.set('Authorization', `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
});

//const dataProvider = restProvider('http://localhost:8080', httpClient);

const dataProvider = restProvider(process.env.REACT_APP_BASE_URL, httpClient);


const CustomDataProvider = dataProvider

export default CustomDataProvider;
