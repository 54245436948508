// Libraries
import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
// Components
import AnalyticsToolBar from "../../components/buildings/Analytics/AnalyticsToolBar";
import Graphics from "../../components/buildings/Graphics/Graphics";
import NoData from "../../components/buildings/Graphics/NoData";
//Assets
import { Card } from "@material-ui/core";

// Style
const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  topContainer : {
    padding: "0 1em",
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px  #2e2e2e33",
  },
  analytics: {
    paddingBottom: "10px",
  },
  toolbar: {
    "& > *": {},
  },
  cardContainer: {
    flex: 1,
    minHeight: "400px", 
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px  #2e2e2e33",
  },
  graphics: {
    display: "flex",
    flex: 1,
    height: "100%",
    minHeight: "400px",
    alignContent: "stretch",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  graphicItem: {
    flex: 1,
    display: "flex",
    padding: "16px",
    overflow: "auto",
  },
  loading: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
}));

const AnalyticsCard = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const [isWeekendsHighlight, setIsWeekendsHighlight] = useState(true);
  const [isNightsHighlight, setIsNightsHighlight] = useState(true);
  const [isNightsHighlightDisaled, setIsNightsHighlightDisaled] =
    useState(false);
  const [isWeekendsHighlightDisabled, setIsWeekendsHighlightDisabled] =
    useState(false);

  // Some Temporal modifications implies other modifications in the toolbar and graphs
  useEffect(() => {
    switch (props.periodAggregate) {
      case "raw":
      case "hour":
        break;
      case "day":
        setIsNightsHighlight(false);
        setIsNightsHighlightDisaled(true);
        setIsWeekendsHighlightDisabled(false);
        break;
      case "month":
        setIsNightsHighlight(false);
        setIsWeekendsHighlight(false);

        setIsWeekendsHighlightDisabled(true);
        setIsNightsHighlightDisaled(true);
        break;
      case "year":
        setIsWeekendsHighlight(false);
        setIsNightsHighlight(false);
        setIsWeekendsHighlightDisabled(true);
        setIsNightsHighlightDisaled(true);
        break;
      default:
        setIsWeekendsHighlight(false);
        setIsNightsHighlight(false);
        setIsWeekendsHighlightDisabled(true);
        setIsNightsHighlightDisaled(true);
        break;
    }
  }, [props.periodAggregate]);

  // Graphics options
  const options = {
    ...props.options, // TODO: Continue from here
    isWeekendsHighlight: isWeekendsHighlight,
    isNightsHighlight: isNightsHighlight,
    aggregation: {
      //     ratio: 1000,
      //     unit_prefix: 'k',
      period: props.periodAggregate,
    },
  };

  return (
    <div className={classes.flexContainer}>
      <div className={classes.analytics}>
        <Card className={classes.topContainer}>
          <AnalyticsToolBar
            chartType={props.chartType}
            periodRange={props.periodRange}
            onChangePeriodRange={props.onChangePeriodRange}
            periodAggregate={props.periodAggregate}
            onChangePeriodAggregate={props.onChangePeriodAggregate}
            periodAggregateDisabled={props.periodAggregateDisabled}
            resourceType={props.resource}
            onChangeResourceType={props.onChangeResource}
            resourcesDisabled={props.resourcesDisabled}
            energyFlowDirection={props.energyFlowDirection}
            onChangeEnergyFlowDirection={props.onChangeEnergyFlowDirection}
            isWeekendsHighlight={isWeekendsHighlight}
            onChangeIsWeekendsHighlight={setIsWeekendsHighlight}
            isWeekendsHighlightDisabled={isWeekendsHighlightDisabled}
            isNightsHighlight={isNightsHighlight}
            onChangeIsNightsHighlight={setIsNightsHighlight}
            isNightsHighlightDisabled={isNightsHighlightDisaled}
            weatherCorrection={props.weatherCorrection}
            onChangeWeatherCorrection={props.setWeatherCorrection}
            surfaceCorrection={props.surfaceCorrection}
            onChangeSurfaceCorrection={props.onChangeSurfaceCorrection}
          />
        </Card>
      </div>
      <Card className={classes.cardContainer}>
        <div className={classes.graphics}>
          {props.timeseries.length < 1 ? (
            <NoData
              footer={t("buildings.show.analytics.graphics.no_data.footer")}
            />
          ) : (
            <div className={classes.graphicItem}>
              <Graphics
                data={props.timeseries}
                type={props.chartType}
                title={props.chartTitle}
                options={options}
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default AnalyticsCard;
