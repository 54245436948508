// Libs
import React from "react";
import {
  Edit,
  SimpleForm,
  useTranslate,
} from "react-admin";

// Components
import CustomRegistrationToolbar from "../CustomToolbars/CustomRegistrationToolbar";
import UserEditForm from "./UserEditForm";

const UserEdit = (props) => {
  const t = useTranslate();

  return (
    <Edit title={t("users.edit.title")} {...props}>
      <SimpleForm toolbar={<CustomRegistrationToolbar />}>
        <UserEditForm />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
