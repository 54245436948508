import React from "react";
import { Admin, Login, Resource } from "react-admin";
// import authProvider from "./authProvider";
import AuthProvider from "./AwsCognitoAuthProvider";
import dataProvider from "./dataProvider";
//import { resolveBrowserLocale} from 'react-admin'
import HomeIcon from '@material-ui/icons/Home';
import BuildingList from "./components/buildings/BuildingList";
import BuildingShow from "./components/buildings/BuildingShow";
import BuildingCreate from "./components/buildings/BuildingCreate";
import BuildingEdit from "./components/buildings/BuildingEdit";
import AlertList from "./components/alerts/AlertList";
import AlertShow from "./components/alerts/AlertShow";
import AlertCreate from "./components/alerts/AlertCreate";
import AlertEdit from "./components/alerts/AlertEdit";
import DeviceList from "./components/devices/DeviceList";
import DeviceShow from "./components/devices/DeviceShow";
// import DeviceCreate from "./components/devices/DeviceCreate";
// import DeviceEdit from "./components/devices/DevicesEdit";
import UserList from "./components/users/UserList";
import UserShow from "./components/users/UserShow";
import UserCreate from "./components/users/UserCreate";
import UserEdit from "./components/users/UserEdit";
import CustomLayout from "./customNavigation/CustomLayout";
import BuildingIcon from "@material-ui/icons/Apartment";
import InsightsIcon from "@mui/icons-material/Insights";
import AodIcon from "@mui//icons-material/Aod";
// import AssignementIcon from "@material-ui/icons/Assignment";
import UserIcon from "@material-ui/icons/People";
import DashboardsIcon from "@mui/icons-material/QueryStats";
import { theme } from "./theme";
import Dashboard from "./components/dashboard/Dashboard";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AnalyticsDashboard from "./modules/analytics/AnalyticsDashboard";
import polyglotI18nProvider from "ra-i18n-polyglot";
import customEnglishMessages from "./i18n/en/en";
import customFrenchMessages from "./i18n/fr/fr";
import dashboardReducer from "./modules/dashboard/reducer";
import timeseriesReducer from "./components/timeseries/reducer";
import timeseriesSaga from "./components/timeseries/sagas";
import DashboardsList from "./modules/dashboards/DashboardsList";
import DashboardsCreate from "./modules/dashboards/DashboardsCreate";
import DashboardsShow from "./modules/dashboards/DashboardsShow";
import DashboardsEdit from "./modules/dashboards/DashboardsEdit";

import { Amplify, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import authProvider from "./authProvider";

Amplify.configure(awsconfig);

const messages = {
  fr: { ...customFrenchMessages },
  en: { ...customEnglishMessages },
};

const customReducers = {
  dashboard: dashboardReducer,
  timeseries: timeseriesReducer,
};

const customSagas = [timeseriesSaga];

const i18nProvider = polyglotI18nProvider(
  (locale) => (messages[locale] ? messages[locale] : messages.en),
  "fr"
  // resolveBrowserLocale()
);

const MyLoginPage = () => (
  <>
    <Login backgroundImage="https://res.cloudinary.com/voltyo-com/image/upload/v1636653969/bobak-emamian-dPhnD4PQ-3c-unsplash_intxmx.jpg" />
  </>
);


// function App() {
function App({ signOut, user }) {
  // const permissions = localStorage.permissions;
  const permissions = "admin";

  return (
    <Admin
      authProvider={AuthProvider}
      dataProvider={dataProvider}
      theme={theme}
      layout={CustomLayout}
      customReducers={customReducers}
      customSagas={customSagas}
      // loginPage={MyLoginPage}
      i18nProvider={i18nProvider}
    >
       <Resource
        name="home"
        list={Dashboard}
        icon={HomeIcon}
      />
      <Resource
        name="buildings"
        list={BuildingList}
        show={BuildingShow}
        create={permissions === "admin" ? BuildingCreate : null}
        edit={permissions === "admin" ? BuildingEdit : null}
        icon={BuildingIcon}
      />
      <Resource
        name="devices"
        list={DeviceList}
        show={DeviceShow}
        // create={permissions === "admin" ? DeviceCreate : null}
        // edit={permissions === "admin" ? DeviceEdit : null}
        icon={AodIcon}
      />
      <Resource
        name="analytics"
        list={AnalyticsDashboard}
        icon={InsightsIcon}
      />
      <Resource
        name="alerts"
        list={AlertList}
        show={AlertShow}
        create={permissions === "admin" ? AlertCreate : null}
        edit={permissions === "admin" ? AlertEdit : null}
        icon={NotificationsIcon}
      />
      <Resource
        name="users"
        list={permissions === "admin" ? UserList : null}
        show={permissions === "admin" ? UserShow : null}
        create={permissions === "admin" ? UserCreate : null}
        edit={permissions === "admin" ? UserEdit : null}
        icon={UserIcon}
      />
      <Resource
        name="dashboards"
        list={DashboardsList}
        show={DashboardsShow}
        create={permissions === "admin" ? DashboardsCreate : null}
        edit={permissions === "admin" ? DashboardsEdit : null}
        icon={DashboardsIcon}
      />
    </Admin>
  );
}

export default withAuthenticator(App);
