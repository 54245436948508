// Lib
import { useRef, useEffect } from "react";
import {
  TextField,
  useTranslate,
} from "react-admin";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

// Material UI components
import { Typography } from "@material-ui/core";

// Components
import BuildingTypesInformation from "../buildingSpec/BuildingTypesInformation";
import DevicesInformations from "../buildingSpec/DevicesInformations";

// Custom Style
import globalUseStyles from "../../../styles/globalCustomStyles";


mapboxgl.accessToken =
  "pk.eyJ1IjoidGJpYXJuZWl4IiwiYSI6ImNsMG1meGM3MjBhNmUzY250ZWZrcnFuaTEifQ.aL3eO-ZeH_V8-xLdQALrjw";

const Overview = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const record = props.record;

  // MapBox  and markers initialisation
  const mapBoxContainer = useRef(null);
  useEffect(() => {
    const map = new mapboxgl.Map(
      {
        container: mapBoxContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [record.longitude, record.latitude],
        zoom: 13,
      },
      []
    );
    new mapboxgl.Marker()
      .setLngLat([record.longitude, record.latitude])
      .addTo(map);
    return () => map.remove();
  }, []);

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">
              {t("buildings.show.overview.name")}
            </Typography>
            <TextField source="name" className={globalClasses.customTextField} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">
              {t("buildings.show.overview.surface")}
            </Typography>
            <TextField source="surface" className={globalClasses.customTextField} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">
              {t("buildings.show.overview.construction_year")}
            </Typography>
            {record.construction_year === null ? (
              <span className={globalClasses.emptyCustomTextField}>
                {t("buildings.show.overview.not_reseigned")}
              </span>
            ) : (
              <TextField
                source="construction_year"
                className={globalClasses.customTextField}
              />
            )}
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">
              {t("buildings.show.overview.building_type")}
            </Typography>
            {record.type === null ? (
              <p className={globalClasses.emptyCustomTextField}>
                {t("buildings.show.overview.not_reseigned")}
              </p>
            ) : (
              <BuildingTypesInformation record={record} />
            )}
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.location")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <TextField
            source="full_address"
            className={globalClasses.customTextField}
          />
          <div className={globalClasses.mapContainer}>
            <div ref={mapBoxContainer} className="map-container" />
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.overview.devices.title")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <DevicesInformations />
        </div>
      </div>
    </div>
  );
};

export default Overview;
