const translateHomeFr = {
  home: {
    name: "Voltyo Assistant Energetique",
    buildings: "bâtiments enregistrés",
    devices: "équipements ajoutés",
    alerts: "alertes configurées",
    dashboards: "tableaux de bord actifs",
  },
};

export default translateHomeFr;
