import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox, ListItemText, makeStyles } from '@material-ui/core';
import { theme }  from './../../theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


// Style
const useStyles = makeStyles((theme) => ({
  select : {
        control: (base, state) => ({
            ...base,
            boxShadow: "none"
            // You can also use state.isFocused to conditionally style based on the focus state
        }),
        "--color": theme.primary
    }
}));


function getStyles(name, item, theme) {
    return {
        fontWeight:
            item.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip(props) {
    //  const theme = useTheme();
      const classes = useStyles();

    // const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        let value = event.target.value;
        value = typeof value === 'string' ? value.split(',') : value;
        props.onChange(
        // On autofill we get a stringified value.
            props.items.filter( item => value.indexOf(item.name) > -1)
        );
  };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
                <Select
                    labelId="multiple-chip-label"
                    id="multiple-chip"
                    multiple
                    value={props.selected.map( item => item.name)}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    styles={classes.select}
                    >
                        {props.items.map((item) => (
                            <MenuItem
                                key={item.name}
                                value={item.name}
                                style={getStyles(item.name, props.selected.map(item => item.name), theme)}
                                >
                                    <Checkbox checked={props.selected.map(item => item.name).indexOf(item.name) > -1} />
                                    <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
  );
}