// Libs
import React from 'react'
// import { useState }  from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from 'react-admin'
// Components
import AnalyticsCorrections from './AnalyticsCorrections';
// import AnalyticsGraphics from './AnalyticsGraphics';
import AnalyticsPeriodAggregate from './AnalyticsPeriodAggregate';
import AnalyticsPeriodRange from './AnalyticsPeriodRange';
import AnalyticsFlowDirection from './AnalyticsFlowDirection';
import AnalyticsResource from './AnalyticsResource';
import AnalyticsHighlights from './AnalyticsHighlights';


const useStyles = makeStyles((theme) => ({
    ToolBarContainer : {
        // margin: 'auto',
        display: "flex",
        alignContent: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        // maxWidth:"850px"
        paddingBottom: "10px"
    },
    ChildContainer: {

    }
}));

const AnalyticsToolBar = (props) => {
    const classes = useStyles();
    const t = useTranslate();

    return (
        <div className={classes.ToolBarContainer}>
            {/* <div className={classes.ChildContainer}>
                <AnalyticsGraphics
                    title={t("buildings.show.analytics.toolbar.graphics.title")}
                    type={props.chartType}
                    onChange={props.onChangeChartType}/>
            </div> */}
            <div className={classes.ChildContainer}>
                <AnalyticsResource
                    title={t("buildings.show.analytics.toolbar.resource.title")}
                    value={props.resourceType}
                    disabled={props.resourcesDisabled}
                    onChange={props.onChangeResourceType} />
            </div>
            <div className={classes.ChildContainer}>
                <AnalyticsFlowDirection
                    title={t("buildings.show.analytics.toolbar.flow_direction.title")}
                    value={props.energyFlowDirection}
                    onChange={props.onChangeEnergyFlowDirection} />

            </div>
            <div className={classes.ChildContainer}>
                <AnalyticsPeriodAggregate
                    title={t("buildings.show.analytics.toolbar.period.title")}
                    value={props.periodAggregate}
                    onChange={props.onChangePeriodAggregate}
                    disabled={props.periodAggregateDisabled}/>
            </div>
            <div className={classes.ChildContainer}>
                <AnalyticsPeriodRange
                    title={t("buildings.show.analytics.toolbar.calendar.title")}
                    onChange={props.onChangePeriodRange}
                    value={props.periodRange}
                    />
            </div>
            <div className={classes.ChildContainer}>
                <AnalyticsHighlights
                    title={t("buildings.show.analytics.toolbar.highlights.title")}
                    isWeekendsHighlight={props.isWeekendsHighlight}
                    onChangeIsWeekendHighlight={props.onChangeIsWeekendsHighlight}
                    isWeekendsHighlightDisabled={props.isWeekendsHighlightDisabled}
                    isNightsHighlight={props.isNightsHighlight}
                    onChangeIsNightsHighlight={props.onChangeIsNightsHighlight}
                    isNightsHighlightDisabled={props.isNightsHighlightDisabled}
                />
            </div>
            <div className={classes.ChildContainer}>
                <AnalyticsCorrections
                    title={t("buildings.show.analytics.toolbar.correction.title")}
                    surfaceCorrection={props.surfaceCorrection}
                    onChangeSurfaceCorrection={props.onChangeSurfaceCorrection}
                    weatherCorrection={props.weatherCorrection}
                    onChangeWeatherCorrection={props.onChangeWeatherCorrection} />
            </div>
        </div>
    )
}

export default AnalyticsToolBar

