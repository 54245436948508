// lib
import React, { useState, useEffect, useRef } from "react";
import CustomDataProvider from "../../../dataProvider";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

// MUI components
import { makeStyles } from "@material-ui/core/styles";

mapboxgl.accessToken =
  "pk.eyJ1IjoidGJpYXJuZWl4IiwiYSI6ImNsMG1meGM3MjBhNmUzY250ZWZrcnFuaTEifQ.aL3eO-ZeH_V8-xLdQALrjw";

const useStyles = makeStyles(() => ({
  mapContainer: {
    height: "100%",
    border: "1px solid #C0C0C0",
    borderRadius: "25px",
    overflow: "hidden",
  },
  mapMarker: {
    backgroundImage: "url('/assests')",
    border: "1px solid black",
    backgroundSize: "cover",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    cursor: "pointer",
    "&:mapboxgl-popup": {
      width: "5000px",
    },
  },
}));

const BuildingMap = (props) => {
  const classes = useStyles();
  const [buildings, setBuildings] = useState();
  const [geojsonCoordinates, setGeoJsonCoordinates] = useState();
  const [bounds, setBounds] = useState();

  // Get all buildings
  useEffect(() => {
    Promise.resolve(CustomDataProvider.getMany("buildings", {ids: props.buildingIds}))
      .then((res) => res.data)
      .then((data) => setBuildings(data));
  }, [props.buildingIds]);

  // Set coordinates of each building
  useEffect(() => {
    if (buildings) {
      setGeoJsonCoordinates(
        buildings.map((buildings) => [buildings.latitude, buildings.longitude])
      );
    }
  }, [buildings]);

  // Set Bounds information to fit card view
  useEffect(() => {
    if (geojsonCoordinates && geojsonCoordinates.length > 0) {
      let minLat = geojsonCoordinates[0][0];
      let maxLat = geojsonCoordinates[0][0];
      let minLong = geojsonCoordinates[0][1];
      let maxLong = geojsonCoordinates[0][1];

      for (let i = 0; i < geojsonCoordinates.length; i++) {
        if (geojsonCoordinates[i][0] < minLat) {
          minLat = geojsonCoordinates[i][0];
        }
        if (geojsonCoordinates[i][0] > maxLat) {
          maxLat = geojsonCoordinates[i][0];
        }
        if (geojsonCoordinates[i][1] < minLong) {
          minLong = geojsonCoordinates[i][1];
        }
        if (geojsonCoordinates[i][1] > maxLong) {
          maxLong = geojsonCoordinates[i][1];
        }
      }
      setBounds([
        [minLong, minLat - 0.5],
        [maxLong, maxLat + 0.5],
      ]);
    }
  }, [geojsonCoordinates]);

  // mapbox creation
  const mapContainer = useRef(null);

  useEffect(() => {
    if (bounds) {
      const map = new mapboxgl.Map(
        {
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [2, 45],
          zoom: 1,
        },
        []
      );

      buildings.forEach((building) => {
        new mapboxgl.Marker()
          .setLngLat([building.longitude, building.latitude])
          .setPopup(
            new mapboxgl.Popup({ anchor: "top", offset: 15 }) // ajoute une popup
              .setHTML(
                `<h4> ${building.name}</h4><p>${building.address}</p><p>${building.zipcode} ${building.city}</p><a href="${process.env.REACT_APP_BASE_URL}/#/buildings/${building.id}/show">Voir le bâtiment</a>`
              )
          )
          .addTo(map);
      });
      map.fitBounds(bounds);
      return () => map.remove();
    }
  }, [bounds]);

  return (<div ref={mapContainer} className={classes.mapContainer} />);
};

export default BuildingMap;
