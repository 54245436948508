// Libraries
import React, { useState } from "react";
import { Loading, useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
// Components
//Assets
import { Card, CardContent } from "@material-ui/core";
import { useGetList } from "react-admin";
import MultipleSelectChip from "../../components/Input/MultipleSelectChip";
import BasicSelect from "../../components/Input/BasicSelect";
import { getObjectToList } from "../../helpers/helper";

import AnalyticsActivePowerCard from "../../components/analytics/AnalyticsActivePowerCard";
import AnalyticsMaximumPowerCard from "../../components/analytics/AnalyticsMaximumPowerCard";
import AnalyticsActiveEnergyCard from "../../components/analytics/AnalyticsActiveEnergyCard";

// Style
const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  analytics: {
    "& > *": {},
  },
  topcard: {
    "padding-bottom": "10px",
  },
  customCardContent: {
    marginBottom: "0 !important",
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    overflow: "hidden",
  },
  topbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  graphics: {},
  loading: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
  },
}));

const AnalyticsDashboard = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const analyticTypes = [
    {
      type: "active_power",
      text: t("analytics.types.active_power"),
    },
    {
      type: "active_energy",
      text: t("analytics.types.active_energy.title"),
    },
    {
      type: "maximum_power",
      text: t("analytics.types.maximum_power.title"),
    },
  ];
  const GridType = [
    {
      type: "operator",
      name: t("analytics.types.active_energy.grid_type.operator"),
      text: t("analytics.types.active_energy.grid_type.operator"),
    },
    {
      type: "supplier",
      name: t("analytics.types.active_energy.grid_type.supplier"),
      text: t("analytics.types.active_energy.grid_type.supplier"),
    },
  ];
  const [analyticType, setAnalyticType] = useState(analyticTypes[2].type);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [gridTypeSelected, setGridTypeSelected] = useState([GridType[0]]);

  const { data, loading, error } = useGetList("buildings", {
    sort: { field: "name", order: "DESC" },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR</p>;
  }

  const AnalyticsTool = (type, data) => {
    switch (type) {
      case "active_power":
        return <AnalyticsActivePowerCard buildings={data} />;
      case "active_energy":
        return (
          <AnalyticsActiveEnergyCard
            buildings={data}
            gridType={gridTypeSelected}
          />
        );
      case "maximum_power":
        return <AnalyticsMaximumPowerCard buildings={data} />;
      default:
        break;
    }
  };

  const handleItemsSelected = (value) => {
    const colors = [
      "#41445d",
      "#48A9FE",
      "#F78302",
      "#B26CA1",
      "#711FFF",
      "#AAD85A",
      "#2CA05C",
      "#B71528",
      "#F391BF",
      "#247BA0",
    ];
    // , '#F5D000']
    const values = value.map((building, index) => ({
      ...building,
      color: colors[index % 10],
    }));
    setItemsSelected(values);
  };

  const handleGridTypeSelected = (value) => {
    setGridTypeSelected(value);
  };
  return (
    <div className={classes.flexContainer}>
      <div className={classes.topcard}>
        <Card sx={{ minWidth: 275 }} className={classes.customCardContent}>
          <CardContent>
            <div className={classes.topbar}>
              <BasicSelect
                label={t("analytics.types.title")}
                items={analyticTypes}
                onChange={setAnalyticType}
              />

              <MultipleSelectChip
                label={"Bâtiments"}
                items={getObjectToList(data)}
                selected={itemsSelected}
                onChange={handleItemsSelected}
              />
              {analyticType === "active_energy" ? (
                <BasicSelect
                  label={"Type de Grille"}
                  items={GridType}
                  onChange={handleGridTypeSelected}
                />
              ) : (
                <></>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      {AnalyticsTool(analyticType, itemsSelected)}
    </div>
  );
};

export default AnalyticsDashboard;
