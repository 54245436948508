

const buildingsReducer = (previousState = 0, { type, payload }) => {
    if (type === 'ASSETS_PERFORMANCE_RECEIVED') {
        return payload.rate;
    }
    return previousState;
}

export default buildingsReducer;
