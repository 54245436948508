// Libs
import { SaveButton, useTranslate } from "react-admin";

const CustomSaveButton = (props) => {
  const t = useTranslate();

  return (
    <SaveButton
      handleSubmitWithRedirect={props.props.handleSubmitWithRedirect}
      label={t("resources.global.save")}
      redirect="show"
      variant="text"
    />
  );
};

export default CustomSaveButton;