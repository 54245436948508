// Libs
import React from 'react';
// import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
// Context
import SelectButtons from './SelectButtons';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "5px 5px 0px 5px",
    },
    TitleContainer : {
        padding: "5px 5px 2px 5px",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize
    },
    ButtonsContainer : {
        borderRadius: "10px",
        border: "2px solid #E5E5E5",
        padding: "10px",
        width: "120px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
    }
}));

const AnalytcisGraphics = (props) => {
    const classes = useStyles();
    const t = useTranslate()

    const buttons = [
        {
            type: "electricity",
            icon: "Electricity",
            tooltip: { text: t("buildings.show.analytics.toolbar.resource.electricity"), position: 'top' },
            disabled: props.disabled?.indexOf('electricity') > -1 ? true : false
        },
        {
            type: "gaz",
            icon: "Gaz",
            tooltip: { text: t("buildings.show.analytics.toolbar.resource.gaz"), position: 'top' },
            disabled: props.disabled?.indexOf('gas') > -1 ? true : false
        },
        {
            type: "water",
            icon: "Water",
            tooltip: { text: t("buildings.show.analytics.toolbar.resource.water"), position: 'top' },
            disabled: props.disabled?.indexOf('water') > -1 ? true : false
        }
    ]

    return (
        <div className={classes.container}>
            <div className={classes.TitleContainer}>
                {props.title}
            </div>
            <div className={classes.ButtonsContainer}>
                <SelectButtons
                    buttons={buttons}
                    changeState={props.onChange}
                    focusValue={props.value}
                    orientation={"horizontal"}
                    borderColor={"#e89712"}
                />
            </div>
        </div>
    );
};


export default AnalytcisGraphics;