// React admin components
import { TextInput, NumberInput, useTranslate } from "react-admin";

// React-final-form  Components
import { Field } from "react-final-form";

// Components
import BuildingTypesInformation from "../buildingSpec/BuildingTypesInformation";

const GeneralBuildingInformations = () => {
  const t = useTranslate();

  return (
    <div>
      <TextInput
        required
        label={t("buildings.create.form_label.name")}
        source="name"
        fullWidth={true}
      />
      <NumberInput
        required
        label={t("buildings.create.form_label.surface")}
        source="surface"
        fullWidth={true}
      />
      <NumberInput
        label={t("buildings.create.form_label.construction_year")}
        source="construction_year"
        fullWidth={true}
      />
      <Field name="type">
        {(props) => (
          <BuildingTypesInformation
            value={props.input.value}
            onChange={props.input.onChange}
          />
        )}
      </Field>
    </div>
  );
};

export default GeneralBuildingInformations;
