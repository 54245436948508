import axios from 'axios'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import AuthProvider from '../AwsCognitoAuthProvider';

// Implementation comming from https://www.youtube.com/watch?v=16-1mTdGBoM

//const baseURL = 'http://localhost:8080';

const baseURL = process.env.REACT_APP_BASE_URL;

// let access_token = localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null;
// let refresh_token = localStorage.getItem('refresh_token') ? JSON.parse(localStorage.getItem('refresh_token')) : null;


let access_token = null

const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization : `Bearer ${access_token}`}
})

axiosInstance.interceptors.request.use( async req => {
    const cognito_session = await AuthProvider.checkAuth();
    let access_token = cognito_session.getAccessToken().getJwtToken();

    console.log(access_token)

    // if (!access_token) {
    access_token = cognito_session?.getAccessToken().getJwtToken();
    req.headers.Authorization =  `Bearer ${access_token}`
    // }

    // if (!refresh_token) {
    //     refresh_token = localStorage.getItem('refresh_token') ? JSON.parse(localStorage.getItem('refresh_token')) : null;
    // }

    // const user = jwt_decode(access_token);
    // const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    // if(!isExpired) return req

    // const response = await axios.post(`${baseURL}/token/refresh`, {}, { headers : {
    //     Authorization: `Bearer ${refresh_token}`}
    // });

    // localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
    // req.headers.Authorization =  `Bearer ${response.data.access_token}`

    return req
})


// function* entries(obj) {
//     for (let key in obj)
//         yield [key, obj[key]];
// }


// axiosInstance.interceptors.response.use( async res => {
//     const headers = res.headers;
//     const status = res.status;
//     const json = res.data;
//     const body = res.data;
//     const statusText = res.statusText;
//     const headers_map = new Map(entries(headers));

//     if (headers_map.has('content-range')){ // This is to fix a ra-simple-rest bug
//         headers_map.set('Content-Range', headers_map.get('content-range'))
//     }
//     console.log(headers_map)

//     // Promise.resolve({ status, headers, body, json }
//     return Promise.resolve({status: status, headers: headers_map, body: body, json: json})
// })


export default axiosInstance

