import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ReactECharts from 'echarts-for-react';

const useStyles = makeStyles((theme) => ({
    chartBar: {
      "& > *": {
      }
    }
}));

const ChartArea = (props) => {
    const classes = useStyles();
    const values = props.data.map( v => v.value)
    const upper = props.data.map( v => v.upper)
    const lower = props.data.map( v => v.lower)
    const timestamps = props.data.map( v => { const date = new Date(v.timestamp);
                                              return date.toLocaleString('default', { month: 'long' })})

    const option = {
            title: {
              text: 'Confidence Band',
              subtext: 'Example in MetricsGraphics.js',
              left: 'center'
            },
            // tooltip: {
            //   trigger: 'axis',
            //   axisPointer: {
            //     type: 'cross',
            //     animation: false,
            //     label: {
            //       backgroundColor: '#ccc',
            //       borderColor: '#aaa',
            //       borderWidth: 1,
            //       shadowBlur: 0,
            //       shadowOffsetX: 0,
            //       shadowOffsetY: 0,
            //       color: '#222'
            //     }
            //   },
            //   formatter: function (params) {
            //     return (
            //       params[2].name +
            //       '<br />' +
            //       ((params[2].value - base) * 100).toFixed(1) +
            //       '%'
            //     );
            //   }
            // },
            // grid: {
            //   left: '3%',
            //   right: '4%',
            //   bottom: '3%',
            //   containLabel: true
            // },
            // xAxis: {
            //   type: 'category',
            //   data: timestamps,
            //   axisLabel: {
            //     formatter: function (value, idx) {
            //       var date = new Date(value);
            //       return idx === 0
            //         ? value
            //         : [date.getMonth() + 1, date.getDate()].join('-');
            //     }
            //   },
            //   boundaryGap: false
            // },
            // yAxis: {
            //   axisLabel: {
            //     formatter: function (val) {
            //       return (val - base) * 100 + '%';
            //     }
            //   },
            //   axisPointer: {
            //     label: {
            //       formatter: function (params) {
            //         return ((params.value - base) * 100).toFixed(1) + '%';
            //       }
            //     }
            //   },
            //   splitNumber: 3
            // },
            // series: [
            //   {
            //     name: 'L',
            //     type: 'line',
            //     data: data.map(function (item) {
            //       return item.l + base;
            //     }),
            //     lineStyle: {
            //       opacity: 0
            //     },
            //     stack: 'confidence-band',
            //     symbol: 'none'
            //   },
            //   {
            //     name: 'U',
            //     type: 'line',
            //     data: data.map(function (item) {
            //       return item.u - item.l;
            //     }),
            //     lineStyle: {
            //       opacity: 0
            //     },
            //     areaStyle: {
            //       color: '#ccc'
            //     },
            //     stack: 'confidence-band',
            //     symbol: 'none'
            //   },
            //   {
            //     type: 'line',
            //     data: data.map(function (item) {
            //       return item.value + base;
            //     }),
            //     itemStyle: {
            //       color: '#333'
            //     },
            //     showSymbol: false
            //   }
            // ]
        }

    return (
        <div className={classes.chartBar}>
            <ReactECharts option={option} />
        </div>
    )
}

export default ChartArea
