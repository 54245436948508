// Libs
import React from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";

// Components
import CustomRegistrationToolbar from "../CustomToolbars/CustomRegistrationToolbar";
import AlertForm from "./AlertForm";

const AlertEdit = (props) => {
  const t = useTranslate();

  return (
    <Edit title={t("alerts.title.edit")} {...props}>
      <SimpleForm toolbar={<CustomRegistrationToolbar />}>
        <AlertForm props={props} />
      </SimpleForm>
    </Edit>
  );
};

export default AlertEdit;
