const translateDashboardsFr = {
  dashboard: {
    last_month_consumption: "Consommation du dernier mois",
    anomalies: "Anomalies",
    contrat_reviews: "Contrats à revoir",
    building: "Bâtiments",
    last_month_billing: "Facture du dernier mois",
    consumption_list: "Consommation des bâtiments",
    billing_list: "Factures des bâtiments",
    anomalies_last: "Dernières Anomalies",
    contracts_list: "Contracts à optmiser",
    contract_power: "Puissance souscrite",
  },
};

export default translateDashboardsFr;