// Libs
import React, { useState, useEffect } from "react";
import {
  useTranslate,
  useRecordContext,
} from "react-admin";

// MUI components
import { Typography } from "@material-ui/core";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

const AlertStrategy = () => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const t = useTranslate();

  if (record.check.strategy === "inferior") {
    return <Typography className={globalClasses.customTextField}>{t("alerts.form.trigger.strategy.superior")}</Typography>;
  } else if (record.check.strategy === "superior") {
    return <Typography className={globalClasses.customTextField}>{t("alerts.form.trigger.strategy.inferior")}</Typography>;
  } else if (record.check.strategy === "equal") {
    return <Typography className={globalClasses.customTextField}>{t("alerts.form.trigger.strategy.equal")}</Typography>;
  }
};

const CheckInterval = () => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const t = useTranslate();

  if (record.check.interval_in_minutes === 1) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_minute")}</Typography>
    );
  } else if (record.check.interval_in_minutes === 5) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_5_minutes")}</Typography>
    );
  } else if (record.check.interval_in_minutes === 15) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_15_minutes")}</Typography>
    );
  } else if (record.check.interval_in_minutes === 30) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_30_minutes")}</Typography>
    );
  } else if (record.check.interval_in_minutes === 60) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_hour")}</Typography>
    );
  } else if (record.check.interval_in_minutes === 360) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_6_hours")}</Typography>
    );
  } else if (record.check.interval_in_minutes === 720) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_12_hours")}</Typography>
    );
  } else if (record.check.interval_in_minutes === 1440) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_day")}</Typography>
    );
  }
};

const NotificationInterval = () => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const t = useTranslate();

  if (record.notification.reminder_interval_in_minutes === 15) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_15_minutes")}</Typography>
    );
  } else if (record.notification.reminder_interval_in_minutes === 30) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_30_minutes")}</Typography>
    );
  } else if (record.notification.reminder_interval_in_minutes === 60) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_hour")}</Typography>
    );
  } else if (record.notification.reminder_interval_in_minutes === 360) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_6_hours")}</Typography>
    );
  } else if (record.notification.reminder_interval_in_minutes === 720) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_12_hours")}</Typography>
    );
  } else if (record.notification.reminder_interval_in_minutes === 1440) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_day")}</Typography>
    );
  } else if (record.notification.reminder_interval_in_minutes === 10080) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_week")}</Typography>
    );
  } else if (record.notification.reminder_interval_in_minutes === 43200) {
    return (
      <Typography className={globalClasses.customTextField}>{t("alerts.form.interval_in_minutes.each_month")}</Typography>
    );
  }
};

const DeviceProperty = ({deviceProperty}) => {
  const globalClasses = globalUseStyles();

  if (deviceProperty.length >= 1) {
    return <Typography className={globalClasses.customTextField}>{deviceProperty[0].name}</Typography>;
  } else {
    return null;
  }
};

const ThresholdValueAndUnit = (props) => {
  const globalClasses = globalUseStyles();
  const record = props.record;
  const deviceProperty = props.deviceProperty;

  if (deviceProperty.length >= 1) {
    return (
      <Typography className={globalClasses.customTextField}>{`${record.check.value_threshold} ${deviceProperty[0].unit ? deviceProperty[0].unit : "" }`}</Typography>
    );
  } else {
    return null;
  }
};

const NotificationStrategy = () => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const [strategies, setStrategies] = useState([]);

  useEffect(() => {
    if (record.notification.strategies) {
      setStrategies(record.notification.strategies);
    }
  }, [record]);

  let strategiesToString = "";
  for (let i = 0; i < strategies.length; i++) {
    if ((i+1) === strategies.length) {
      strategiesToString += `${strategies[i]}`
    } else {
      strategiesToString += `${strategies[i]}, `
    }
  }

  return <Typography className={globalClasses.customTextField}> {strategiesToString} </Typography>;
};

export {AlertStrategy, CheckInterval, NotificationInterval, DeviceProperty, ThresholdValueAndUnit, NotificationStrategy};