import React, { useState } from 'react'
import { Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Calendar from "./Calendar"

const useStyles = makeStyles((theme) => ({
    container :  {
        display: "flex"
    },
    button :  {
        border: "2px solid #E5E5E5",
        fontSize: "12px",
        display: "flex"
    }
}));

const CalendarSelector = props => {
    const classe = useStyles();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setCalendarOpen(true)
    };

    return (
        <div className={classe.container}>
            <Button aria-describedby={'id'} className={classe.button} onClick={handleClick}>
                {props.title}
            </Button>
            <Popover
                id={'id'}
                open={calendarOpen}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'top' }}
                transformOrigin={{ vertical: 'top', horizontal: 'top' }}
            >
                <Calendar
                    onChange={val => props.handleCalendarChange(val)}
                    handleClickOutside={() => setCalendarOpen(false)}
                    value={props.value}
                />
            </Popover>
        </div>
    )
}


export default CalendarSelector
