// Libs
import React from "react";
import {
  Show,
  SimpleShowLayout,
  useTranslate,
} from "react-admin";

// Components
import UserShowDetails from "./UserShowDetails";

const UserShow = (props) => {
  const t = useTranslate();

  return (
    <Show title={t("users.show.title")} {...props}>
      <SimpleShowLayout>
        <UserShowDetails />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
