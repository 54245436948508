import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ChartBar from './ChartBar'
import ChartArea from './ChartArea'
import ChartLine from './ChartLine'
import ChartColumn from './ChartColumn'

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
    },
    graphics: {
        height: "100%",
        width: "98%",
    }
}));

const Graphics = (props) => {
    const classes = useStyles();
    let data = [...props.data];

    const ChartChoice = (type) => {
      switch (type) {
        case 'ChartBar':
        case 'chart_horizontal_bar':
            return <ChartBar title={props.title} name={props.name} data={data} options={props.options} />
        case 'ChartArea':
            return <ChartArea title={props.title} name={props.name} data={data} />
        case 'ChartLine':
        case 'chart_line':
            return <ChartLine title={props.title} name={props.name} data={data} options={props.options} />
        case 'ChartColumn':
        case 'chart_column_bar':
            return <ChartColumn title={props.title} name={props.name} data={data} options={props.options} />
        default:
          break;
      }
    }

    if (props.options.aggregation?.ratio ) {
        const ratio = props.options.aggregation?.ratio;
         data = data.map( item => {
            let item_copy = Object.assign({},item);
            item_copy.series = item.series.map( i => { return {value: i.value/ratio, timestamp : i.timestamp } } )
            item_copy.unit = props.options.aggregation.unit_prefix + item_copy.unit;
            return item_copy
         })
    }

    return (
        <div className={classes.container}>
            <div className={classes.graphics}>
                {ChartChoice(props.type)}
            </div>
        </div>
    )
}

export default Graphics
