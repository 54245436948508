const translateBuildingsFr = {
  buildings: {
    create: {
      title: "Ajouter un bâtiment",
      form: {
        previous: "Etape précédente",
        next: "Etape suivante",
        step_error: "Erreur : étape inconnue",
        cancel: "Annuler",
        canceled: "Annulation de la création du bâtiment",
        save: "Enregistrer",
        client_error: "Erreur lors de la soumission du formulaire.",
        server_error:
          "Impossible de soumettre le formulaire. Retentez dans quelques instants ou contactez l'administrateur du site.",
        end: "Nouveau bâtiment enregistré avec succès !",
        to_building_list: "Revenir à la liste des bâtiments",
        add_another_building: "Ajouter un nouveau bâtiment",
      },
      subtitle: {
        title: "Ajouter un bâtiment",
        step_one: "Renseignez un nom, la surface et le type",
        step_two: "Renseignez l'adresse du bâtiment",
        step_three: "Renseignez les équipements du bâtiment",
      },
      form_label: {
        name: "Nom du bâtiment",
        surface: "Surface du bâtiment en m2",
        construction_year: "Année de construction du bâtiment",
        adresse: "Adresse du bâtiment",
        device_type: "Type d'équipement",
        meter_type: "Type de compteur",
        meter_number: "Numéro de compteur",
        property_pdl: "PDL",
        property_pce: "PCE",
        property_sensor: "Référence du capteur",
        property_supplier: "Distributeur",
        device_name: "Nom de l'équipement"
      },
      type: {
        title: "Type",
        hospital: "Hôpital",
        hotel: "Hôtel",
        industry: "Industrie",
        office: "Bureaux",
        school: "École",
        store: "Magasin",
        pharmacy: "Pharmacie",
      },
      section: {
        building: "Bâtiment",
        address: "Adresse",
        location: "Localisation",
        surface: "Surface (m2)",
        construction_year: "Année de construction",
        devices: "Équipements",
        meter: "Compteurs",
        general: "Informations générales",
        usage: "Informations d'usage",
      },
      devices: {
        title: "Équipements",
        type: "Type d'équipement",
        heater: "Chauffage",
        cooler: "Climatisation",
      },
      meters: {
        title: "Compteurs",
        electricity_meter: "Compteur d'électricité",
        gas_meter: "Compteur de gaz",
        sensor: "Capteur",
      },
      meter_number: {
        title: "Numéro de compteur",
        electricity_meter: "Numéro de PDL",
        gas_meter: "Numéro de PCE",
        power: "Puissance",
      },
    },
    show: {
      title: {
        main: "Afficher le bâtiments",
        general_informations: "Informations générales",
        specifications: "Caractéritiques",
        location: "Localisation",
      },
      edit: {
        button: "Editer",
      },
      tabs: {
        summary: "Bilan",
        equipments: "Appareils",
        buildingworks: "Travaux",
        reports: "Rapports",
        map: "Carte",
        graphs: "Graphiques",
      },
      overview: {
        name: "Nom du bâtiment",
        surface: "Surface (m2)",
        construction_year: "Année de construction",
        building_type: "Type",
        not_reseigned: "Non renseigné",
        devices: {
          title: "Equipements",
          no_device: "Pas encore d'équipement enregistré",
          electricity_meter: "Compteur d'électricité",
          gas_meter: "Compteur de gaz",
          water_meter: "Compteur d'eau",
          sensor: "Capteur",
          meter_number: "Numéro de compteur",
          sensor_number: "Numéro de capteur",
          pdl: "PDL",
          pce: "PCE",
        },
        location: "Localisation",
      },
      devices_list: {
        created_at: "Créé le",
        updated_at: "Dernière mise à jour le",
      },
      analytics: {
        toolbar: {
          graphics: {
            title: "Graphiques",
            tooltips: {
              chart_bar:
                "Consommation moyenne par an/mois/jour sur la période sélectionnée",
              chart_line: "Courbe de charge sur la période sélectionnée",
              chart_stack:
                "Consommation sur la période sélectionnée (histogramme)",
              chart_area:
                "Prédictions hautes et basses sur la période sélectionnée",
            },
          },
          period: {
            title: "Temporalité",
            hour: "Heure",
            day: "Jour",
            month: "Mois",
            year: "Année",
          },
          calendar: {
            title: "Période",
          },
          flow_direction: {
            title: "Flux énergétique",
            production: "Prod",
            consumption: "Conso",
            maximum_power: "Puiss. Max.",
          },
          resource: {
            title: "Ressource",
            water: "Eau",
            gaz: "Gaz",
            electricity: "Électricité",
          },
          highlights: {
            title: "Mise en avant",
            tooltips: {
              weekends:
                "Mettre en avant les weekends sur le graphe. Disponible sur la temporalité HEURE et JOUR.",
              nights:
                "Mettre en avant les nuits sur le graphe. Disponible sur la temporalité HEURE.",
            },
          },
          correction: {
            title: "Correction",
            weather: "par Méteo",
            surface: "par m2",
          },
        },
        graphics: {
          chart_line: {
            title: {
              active_power: {
                raw: "Courbe de Charge",
                hour: "Courbe de Charge",
                day: "Courbe de Charge par jour",
                month: "Courbe de Charge par mois",
                year: "Courbe de Charge par an",
              },
              active_energy: {
                raw: "Consommation",
                hour: "Consommation par heure",
                day: "Consommation par jour",
                month: "Consommation par mois",
                year: "Consommation par an",
              },
              maximal_power: {
                raw: "Puissance maximal",
                hour: "Puissance maximal par heure",
                day: "Puissance maximal par jour",
                month: "Puissance maximal par mois",
                year: "Puissance maximal par an",
              },
            },
          },
          chart_column: {
            title: {
              active_power: {
                raw: "Courbe de Charge",
                hour: "Courbe de Charge",
                day: "Courbe de Charge par jour",
                month: "Courbe de Charge par mois",
                year: "Courbe de Charge par an",
              },
              active_energy: {
                raw: "Consommation",
                hour: "Consommation par heure",
                day: "Consommation par jour",
                month: "Consommation par mois",
                year: "Consommation par an",
              },
              maximal_power: {
                raw: "Puissance maximal",
                hour: "Puissance maximal par heure",
                day: "Puissance maximal par jour",
                month: "Puissance maximal par mois",
                year: "Puissance maximal par an",
              },
            },
          },
          no_data: {
            footer: "Aucune donnée connue.",
          },
        },
      },
    },
    edit: {
      title: "Editer le bâtiment",
    },
  },
};

export default translateBuildingsFr;
