// Libs
import React from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";

// MUI components
import { Typography } from "@material-ui/core";

// Components
import GeneralBuildingInformations from "./FormComponents/GeneralBuildingInformations";
import BuildingDevices from "./FormComponents/BuildingDevices";
import BuildingLocalisation from "./FormComponents/BuildingLocalisation";
import CustomRegistrationToolbar from "../CustomToolbars/CustomRegistrationToolbar";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";


const BuildingEdit = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  return (
    <Edit title={t("buildings.edit.title")} {...props}>
      <SimpleForm toolbar={<CustomRegistrationToolbar />}>
        <div className={globalClasses.globalContainer}>
          <div className={globalClasses.cardContainer}>
            <div className={globalClasses.cardLeftPart}>
              <Typography variant="h4">
                {t("buildings.show.title.general_informations")}
              </Typography>
            </div>
            <div className={globalClasses.cardRightPart}>
              <div className={globalClasses.insideCardRightPart}>
                <GeneralBuildingInformations />
              </div>
            </div>
          </div>
          <div className={globalClasses.cardContainer}>
            <div className={globalClasses.cardLeftPart}>
              <Typography variant="h4">
              {t("buildings.show.title.location")}
              </Typography>
            </div>
            <div className={globalClasses.cardRightPart}>
              <div className={globalClasses.insideCardRightPart}>
                <BuildingLocalisation props={props} />
              </div>
            </div>
          </div>

          <div className={globalClasses.cardContainer}>
            <div className={globalClasses.cardLeftPart}>
              <Typography variant="h4">
              {t("buildings.show.overview.devices.title")}
              </Typography>
            </div>
            <div className={globalClasses.cardRightPart}>
              <BuildingDevices />
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default BuildingEdit;
