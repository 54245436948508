// Libs
import React, { useState } from "react";
import { useTranslate, Create, SimpleForm } from "react-admin";
import { Typography } from "@material-ui/core";

// Components
import CustomToolbar from "./BuildingCreate/CustomToolbar";
import StepsComponents from "./BuildingCreate/StepsComponents";
import GeneralBuildingInformations from "./FormComponents/GeneralBuildingInformations";
import BuildingDevices from "./FormComponents/BuildingDevices";
import BuildingLocalisation from "./FormComponents/BuildingLocalisation";

const BuildingCreate = (props) => {

  const t = useTranslate();
  const [activeStep, setActiveStep] = useState(0);

  const SectionTitle = (props) => {
    return (
      <Typography variant="h5" gutterBottom>
        {props.label}
      </Typography>
    );
  };

  // Form step manager
  const getSteps = () => {
    return ["1", "2", "3"];
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <SectionTitle label={t("buildings.create.section.general")} />
            <GeneralBuildingInformations />
          </div>
        );
      case 1:
        return (
          <div>
            <SectionTitle label={t("buildings.create.section.location")} />
            <BuildingLocalisation />
          </div>
        );
      case 2:
        return (
          <div>
            <SectionTitle label={t("buildings.create.section.devices")} />
            <BuildingDevices />
          </div>
        );
      default:
        return "error";
    }
  };
  const steps = getSteps();

  return (
    <Create title={t("buildings.create.title")} {...props}>
      <SimpleForm
        toolbar={
          <CustomToolbar
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
          />
        }
      >
        <StepsComponents
          activeStep={activeStep}
          steps={steps}
          getStepContent={getStepContent}
        />
      </SimpleForm>
    </Create>
  );
};

export default BuildingCreate;
