// Lib
import React from "react";
import { List, Datagrid, TextField } from "react-admin";

// Component
import BuildingListActions from "./CustomButtons/BuildingFilterButton";

const BuildingList = (props) => {

  return (
    <List {...props} actions={<BuildingListActions props={props} />}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="surface" />
        <TextField source="address" />
        <TextField source="city" />
        <TextField source="construction_year" />
      </Datagrid>
    </List>
  );
};

export default BuildingList;
