

const toTimestamp = (strDate) => {
    const dt = new Date(strDate).getTime();
    return dt / 1000;
  }

const getObjectToList = (dict) => {
    let list = []
    for (const [key, value] of Object.entries(dict)) {
        list.push(value)
    }
    return list
}

// Get Ids based on a specific type
const getDeviceIds = (dict, type) => {
    let list = []
    for (const [key, value] of Object.entries(dict)) {
        list.push(value)
    }
    return list.map( item => item.devices.filter( item => item.type === type)).flat()
};

const getPropertyId = (dict, type) => {
    const property = dict.properties.filter( item => item.name === type )
    if (property.length > 0)
        return property[0].id
    return undefined
};

// Get Ids based on a specific type
const getInitialPeriodRange = (periodAggregate) => {

    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
    let startDate = today

    switch(periodAggregate){
        case 'raw':
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-7, 0, 0, 0);
            break;
        case 'day':
            startDate = new Date(today.getFullYear(), today.getMonth()-1, today.getDate(), 0, 0, 0);
            break;
        case 'month':
            startDate = new Date(today.getFullYear()-1, today.getMonth(), 1, 0, 0, 0);
            break;
        case 'year':
            startDate = new Date(today.getFullYear()-2, 0, 1, 0, 0, 0);
            break;
        default:
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            break;
    }
    return [startDate, endDate]
};


const filterDate = (timestamp, periodAggregate) => {
    const date = new Date(timestamp)

    switch(periodAggregate){
        case 'day':
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        case 'month':
            return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
        case 'year':
            return new Date(date.getFullYear(), 1, 1, 0, 0, 0);
        default:
            return date
    }
}

const filterDateLabel = (timestamp, periodAggregate) => {
    const monthNames = ["Jan", "Fev", "Mar", "Avr", "May", "Jun", "Jul", "Aout", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(timestamp)
    switch(periodAggregate){
        case 'day':
            return date.getDay() + '/' + date.getMonth() + '/' + date.getFullYear();
        case 'month':
            return monthNames[date.getMonth()] + '/' + date.getFullYear();
        case 'year':
            return date.getFullYear();
        default:
            return timestamp
    }
}

export {toTimestamp, getObjectToList, getDeviceIds, getPropertyId, getInitialPeriodRange, filterDate, filterDateLabel};