// Libs
import { useTranslate, Button } from "react-admin";
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customAddButton: {
      fontWeight: 'normal !important',
      color: "#000 !important",
      backgroundColor: "#FFF !important",
      boxShadow: "none !important",
      "&:hover": {
        background: "#FFF !important",
        fontWeight: 'bold !important',
      },
  },
}));

const CustomRemoveButton = (props) => {
  const classes = useStyles();
  const t = useTranslate()

  return <Button startIcon={<RemoveCircleOutlineRoundedIcon />} label={t("resources.global.remove")} onClick={props.onClick} className={classes.customAddButton}/>;
};

export default CustomRemoveButton;
