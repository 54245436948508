import { useState, useEffect } from 'react'
import AnalyticsCard from "./AnalyticsCard";
import { getDeviceIds, getInitialPeriodRange, getPropertyId, toTimestamp } from '../../helpers/helper';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslate } from 'react-admin';
import { getTimeseriesData } from '../timeseries/helpers';


const AnalyticsMaximumPowerCard = (props) => {
    const t = useTranslate()
    const [periodAggregate, setPeriodAggregate] = useState('day');
    const [resource, setResource] = useState('electricity');
    const [surfaceCorrection, setSurfaceCorrection] = useState(false)
    const [weatherCorrection, setWeatherCorrection] = useState(false)
    const [periodRange, setPeriodRange] = useState(getInitialPeriodRange(periodAggregate))
    const [deviceIds,setDeviceIds] = useState(getDeviceIds(props.buildings, 'electricity_meter'))
    const timeseries = useSelector(state => state.timeseries)
    const [toggleRender, setToggleRender] = useState(true)
    const [graphData, setGraphData] = useState([])

    useEffect(() => {
        const initial = getInitialPeriodRange(periodAggregate)
        const _ = require('lodash')
        if (_.isEqual(periodRange,initial) === false){
            setPeriodRange(getInitialPeriodRange(periodAggregate))
        }
    }, [periodAggregate]);

    // Check whether the deviceIds are the same
    useEffect(() => {
        const _ = require('lodash')
        const newDeviceIds = getDeviceIds(props.buildings, 'electricity_meter')
        if (_.isEqual(deviceIds,newDeviceIds) === false){
            setDeviceIds(newDeviceIds)
        }
    }, [props.buildings, deviceIds]);


    // Make API calls
    useEffect(() => {
        setGraphData([])
        const sources = deviceIds.map( (item) => {
            return {
                    type: 'device',
                    id: item.id,
                    property: getPropertyId(item, "maximum_power")
                }
            // Apply arithmethic currections: Surface or Weather
        });

        if (sources.length > 0) {
            const moment = require('moment')
            const params = {
                    aggregation_period: periodAggregate,
                    aggregation_type: 'raw',
                    start_date: moment(periodRange[0]).format(),
                    end_date: moment(periodRange[1]).format(),
                    sources: sources
                }
            getTimeseriesData(params).then((data) => {
                setGraphData(data?.data?.map((d) => {
                    d.series.sort(function(x, y){
                        return toTimestamp(x.timestamp) - toTimestamp(y.timestamp);
                    })
                    return ({...d, series: d.series.map((serie, idx) => {
                            return ({ timestamp: serie.timestamp, value: serie.value });
                        })
                    })
                }));
            });
        }
    }, [periodRange, periodAggregate, deviceIds, weatherCorrection, surfaceCorrection]);

    //     let actions = deviceIds.map( (item) => {
    //         const moment = require('moment')
    //         const start_date = moment(periodRange[0]).format()
    //         const end_date = moment(periodRange[1]).format()
    //         let params = {
    //             device_id: item.id,
    //             aggregate_type: 'raw',
    //             aggregate_period: periodAggregate,
    //             start_date: start_date,
    //             end_date: end_date,
    //         };

    //         // Apply arithmethic currections: Surface or Weather
    //         let post_aggregate = []
    //         if (surfaceCorrection) {
    //             post_aggregate.push({category: 'arithmetic', operation: '/', value : item.surface })
    //         }
    //         if (weatherCorrection) {
    //             post_aggregate.push({category: 'functional', operation: 'weather'})
    //         }
    //         if ( post_aggregate.length !== 0 ) {
    //             params.post_aggregate = post_aggregate;
    //         }

    //         // Use adequate end point
    //         return {type: 'GET_TIMESERIES_MAXIMUM_POWER', params }
    //     })

    //     if ( actions.length === 0 ) {
    //         actions = [{type: 'CLEAR_TIMESERIES'}]
    //     }

    //     actions.map( item => dispatch(item))

    // }, [periodRange, deviceIds, weatherCorrection, surfaceCorrection, dispatch]);

    useEffect(() => {
        graphData?.map( (item) => {
            const device = deviceIds.filter( device => device.id === item.device_id)[0]
            const building = props.buildings.filter( building => building.id === device.parent)[0]
            item.name = building?.name
            if ( item.pool_price) {
                item.name = item.name?.concat(" - ", item.pool_price)
            }
            if (item.season) {
                item.name = item.name?.concat(" - ", item.season)
            }

            let opacity = 'FF'
            if (item.season === 'summer') {
                if (item.pool_price === 'peak'){
                    opacity = 'CC'
                }else {
                    opacity = 'B2'
                }
            } else {
                if (item.pool_price === 'peak'){
                    opacity = 'E5'
                }else {
                    opacity = 'FF'
                }
            }

            item.color = building?.color + opacity
        })
        setToggleRender(!toggleRender)
    }, [graphData])

    return <AnalyticsCard
        chartType={'ChartColumn'}
        buildings={props.buildings}
        resource={resource}
        resourcesDisabled={['water','gas']}
        periodAggregate={periodAggregate}
        periodAggregateDisabled={['hour']}
        onChangePeriodAggregate={setPeriodAggregate}
        periodRange={periodRange}
        onChangePeriodRange={setPeriodRange}
        surfaceCorrection={surfaceCorrection}
        onChangeSurfaceCorrection={setSurfaceCorrection}
        weatherCorrection={weatherCorrection}
        onChangeWeatherCorrection={setWeatherCorrection}
        chartTitle={t('analytics.types.maximum_power.title')}
        timeseries={graphData}/>

}

export default AnalyticsMaximumPowerCard;