import update from 'immutability-helper';

const initialState = {
    load_curve : [],
    maximum_power: [],
    loading: true
  }

const timeseriesReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        // SUCCEEDED
        case 'CLEAR_TIMESERIES_SUCCEEDED':
            state = update(state, { load_curve: { $set: [] } } )
            state = update(state, { active_energy: { $set: [] } } )
            state = update(state, { maximum_power: { $set: [] } } )
            state = update(state, { loading: { $set: false } })
            break;
        case 'GET_TIMESERIES_LOAD_CURVE_CONSUMPTION_SUCCEEDED':
            state = update(state, { load_curve: { $push: payload.data } } )
            state = update(state, { loading: { $set: false } })
            break;
        case 'GET_TIMESERIES_ACTIVE_ENERGY_SUCCEEDED':
            state = update(state, { active_energy: { $push: payload.data } } )
            state = update(state, { loading: { $set: false } })
            break;
        case 'GET_TIMESERIES_MAXIMUM_POWER_SUCCEEDED':
            state = update(state, { maximum_power: { $push: payload.data } } )
            state = update(state, { loading: { $set: false } })
            break;
        // PENDING
        case 'CLEAR_TIMESERIES_PENDING':
            state = update(state, { load_curve: { $set: [] } } )
            state = update(state, { active_energy: { $set: [] } } )
            state = update(state, { maximum_power: { $set: [] } } )
            state = update(state, { loading: { $set: true } })
            break;
        case 'GET_TIMESERIES_LOAD_CURVE_CONSUMPTION_PENDING':
            state = update(state, { load_curve: { $set: [] } } )
            state = update(state, { loading: { $set: true } })
            break;
        case 'GET_TIMESERIES_ACTIVE_ENERGY_PENDING':
            state = update(state, { active_energy: { $set: [] } } )
            state = update(state, { loading: { $set: true } })
            break;
        case 'GET_TIMESERIES_MAXIMUM_POWER_PENDING':
            state = update(state, { maximum_power: { $set: [] } } )
            state = update(state, { loading: { $set: true } })
            break;
        // FAILED
        case 'GET_TIMESERIES_LOAD_CURVE_CONSUMPTION_FAILED':
        case 'GET_TIMESERIES_LOAD_CURVE_PRODUCTION_FAILED':
            state = update(state, { load_curve: { $set: [] } } )
            state = update(state, { loading: { $set: false } })
            break;
        case 'GET_TIMESERIES_ACTIVE_ENERGY_FAILED':
            state = update(state, { active_energy: { $set: [] } } )
            state = update(state, { loading: { $set: false } })
            break;
        case 'GET_TIMESERIES_MAXIMUM_POWER_FAILED':
            state = update(state, { maximum_power: { $set: [] } } )
            state = update(state, { loading: { $set: false } })
            break;
        default:
            break;
    }
    return state;
}

export default timeseriesReducer;
