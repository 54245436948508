// Libs
import React from "react";
import { Create, SimpleForm, useTranslate } from "react-admin";

// Components
import CustomRegistrationToolbar from "../CustomToolbars/CustomRegistrationToolbar";
import AlertForm from "./AlertForm";

const AlertCreate = (props) => {
  const t = useTranslate();

  return (
    <Create title={t("alerts.title.create")} {...props}>
      <SimpleForm toolbar={<CustomRegistrationToolbar />}>
        <AlertForm props={props} />
      </SimpleForm>
    </Create>
  );
};

export default AlertCreate;
