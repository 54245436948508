import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ReactECharts from 'echarts-for-react';
import { useTranslate } from 'react-admin';
import { useThemeProps } from '@mui/material';
import { filterDate, filterDateLabel } from '../../../helpers/helper'

const useStyles = makeStyles((theme) => ({
    chartColumn: {
        height: "100%",
        width: "100%"
    }
}));

var formatTooltipLine = function(color, text){
    return "<span style='display:inline-block;width:10px;height:10px;border-radius:50%;background-color:"+color+";margin-right:5px;'></span><span><b>"+text+"<b></span>"
}


const getWeekendMarkAreas = ( timestamps ) => {
    const uniqueTimestamps = [...new Set(timestamps)]; // Get unique timestamps
    const MaxTimestamp =  new Date(Math.max(...uniqueTimestamps))
    const MinTimestamp =  new Date(Math.min(...uniqueTimestamps))

    if ( MinTimestamp.getDay() === 6 ) {
        MinTimestamp.setDate(MinTimestamp.getDate() - 1)
    }
    if ( MaxTimestamp.getDay() === 5 ) {
        MaxTimestamp.setDate(MaxTimestamp.getDate() + 1 )
    }

    let weekendMarkArea = [];
    let weekends = [];
    for ( let ts = MinTimestamp; ts <= MaxTimestamp; ts.setDate(ts.getDate() + 1) ) {
        if ( ts.getDay() === 5 ) {
            weekends.push(new Date(ts.setHours(12, 0, 0 , 0)));
        }
        if ( ts.getDay() === 6 ) {
            weekends.push(new Date(ts.setHours(36, 0, 0, 0)));

            weekendMarkArea.push([
                {
                    xAxis: weekends[0],
                    itemStyle: { color: '#FDDA0D80'}
                }, {
                    xAxis: weekends[1],
                    itemStyle: { color: '#FDDA0D80'}
                }
            ]);
            weekends = []
      }
  }
    return weekendMarkArea
}


const ChartColumn = (props) => {
    const t = useTranslate();
    const classes = useStyles();
    const series_set = props.data.map( v => v.series);
    const names_set = props.data.map( v=> v.name)
    const colors_set = props.data.map( v=> v.color)
    const units_set = props.data.map ( v => v.unit );

    let timestamps_arrays = []
    let timestamps = []
    if ( props.options.isWeekendsHighlight ) {
        timestamps_arrays = series_set.map( s => s.map( c => new Date(c.timestamp) ) );
        timestamps = [].concat.apply([], timestamps_arrays);
    }

    const weekendMarkAreas = props.options.isWeekendsHighlight ? getWeekendMarkAreas(timestamps) : [];

    const series = series_set.map( (curve, index) => {
        const data = curve.map(v => [filterDate(v.timestamp, props.options.aggregation?.period), v.value] );
        return {
            name: names_set[index],
            type: 'bar',
            smooth: true,
            showSymbol: false,
            itemStyle: {
            color: colors_set[index]},
            data: data,
            markArea: {
                data: index === 0 ? weekendMarkAreas : [],
            }
        }
    });

    const option = {
        title: {
            top: '0',
            left: 'center',
            text: props.title,
            subtext: "",
            textStyle: {
                fontSize: 18,
                fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
            },
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                return `${new Date(params[0].axisValueLabel).toLocaleDateString("fr")} ${new Date(params[0].axisValueLabel).toLocaleTimeString("fr")}<br />
                        ${params.map( item => formatTooltipLine(item.color, item.seriesName + ": " + item.value[1] + " " + units_set[item.seriesIndex])).join("<br/>")}`
           }
        },
        legend: {
            type: 'scroll',
            top: '10%',
            data: names_set,
            textStyle: {
                fontSize: 12,
                fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
            },
        },
        grid: {
            top: '20%',
            left: '2%',
            right: '2%',
            bottom: '15%',
            containLabel: true
        },
        toolbox: {
            feature: {
                dataZoom: {
                    brushStyle: {
                        color: '#41445D',
                        opacity: 0.2
                    }
                },
                restore: {},
                saveAsImage: {},
                xAxisIndex: {},
                yAxisIndex: {},
                magicType: [ 'line', 'bar' ],
                dataView: {}
            }
        },
        xAxis: {
            type: props?.options?.xAxis?.type ? props.options.xAxis?.type : props.options.aggregation?.period === 'day' ? 'time' : 'category',
            boundaryGap: false,
        },
        yAxis: {
            name: 'Consommation ('+ units_set[0] + ')',
            type: 'value',
            nameLocation: 'middle',
            nameGap: 50
        },
        dataZoom: [
            {
                type: 'slider',
                start: 0,
                end: 100
            }
        ],
        dataset: {
            transform: [
                {
                    type: 'sort',
                    config: {dimension: 1, order: 'desc'}
                }
            ]
        },
        series: series
    };

    return (
        <div className={classes.chartColumn}>
            <ReactECharts 
                notMerge={true}
                option={option} 
                style={{height: '100%', width: '100%'}} />
        </div>
    )
}

export default ChartColumn