import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChartColumn } from '@fortawesome/pro-regular-svg-icons';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons';
import { faChartPyramid } from '@fortawesome/pro-regular-svg-icons';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { faChartArea } from '@fortawesome/pro-regular-svg-icons';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons';
import { faMap } from '@fortawesome/pro-regular-svg-icons';
import { faSquare8 } from '@fortawesome/pro-regular-svg-icons';

const Icons = {
    ChartColumn: faChartColumn,
    ChartBar: faChartBar ,
    ChartLine: faChartLine,
    ChartStack: faChartPyramid ,
    ChartArea: faChartArea,
    ChartMixed: faChartMixed,
    Calendar: faCalendar,
    ChartMap: faMap,
    ChartNumber: faSquare8,
};


const BasicSelect = (props) => {
    const [itemType, setItemType] = useState(props.value != undefined ? props.value : props.items[0].type);

    const handleChange = (event) => {
        setItemType(event.target.value);
    };

    useEffect(() => {
        props.onChange(itemType)
    }, [itemType]);

    return (
        <Box sx={{ minWidth: 120 }}>
        <FormControl className={props.className} sx={{ m: 1, width: 200}}>
            <InputLabel id="basic-select-label">{props.label}</InputLabel>
            <Select
                labelId="basic-select-label"
                id="basic-select"
                value={itemType}
                label={props.label}
                onChange={handleChange}>
                    {props.items.map( item => <MenuItem value={item.type}>
                        { item.icon && (
                            <FontAwesomeIcon style={{marginRight: '8px'}} icon={Icons[item.icon]} size="lg" />
                        )}
                        {item.text}
                    </MenuItem>)}
            </Select>
        </FormControl>
        </Box>
    );
}

export default BasicSelect;