// Lib
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  BooleanField,
  useTranslate
} from "react-admin";

// Components
import CustomCreateButton from "../../styles/CustomButtons/CustomCreateButton";

const BuildingList = (props) => {
  const t = useTranslate();
  const label = t("alerts.title.create")

  return (
    <List {...props} actions={<CustomCreateButton props={props} label={label}/>}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <ReferenceField reference="devices" source="check.device">
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceArrayField reference="users" source="notification.users">
          <SingleFieldList>
            <ChipField source="username" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="notification.last_notified_at" />
        <BooleanField source="is_active" />
      </Datagrid>
    </List>
  );
};

export default BuildingList;
