import { useTranslate } from "react-admin";
import {
  ArrayField,
  Datagrid,
  DateField,
  useRecordContext,
  ReferenceField,
} from "react-admin";

// Material UI components
import { makeStyles } from "@material-ui/core/styles";

// Font Awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFireFlameCurved,
  faDroplet,
  faBolt,
  faSensorOn,
} from "@fortawesome/pro-regular-svg-icons";

// Custom Style
import globalUseStyles from "../../../styles/globalCustomStyles";

// Custom list style
const useStyles = makeStyles({
  headerCell: {
    color: "#000 !important",
  },
});

const DeviceName = () => {
  const record = useRecordContext();

  return (
    <ReferenceField source="id" reference="devices">
      <span>{record.name}</span>
    </ReferenceField>
  );
};

const DeviceType = () => {
  const record = useRecordContext();

  if (record.type === "electricity_meter") {
    return <FontAwesomeIcon icon={faBolt} size="1x" />;
  } else if (record.type === "gas_meter") {
    return <FontAwesomeIcon icon={faFireFlameCurved} size="1x" />;
  } else if (record.type === "sensor") {
    return <FontAwesomeIcon icon={faSensorOn} size="1x" />;
  } else if (record.type === "water_meter") {
    return <FontAwesomeIcon icon={faDroplet} size="1x" />;
  } else {
    return <span>{record.type}</span>;
  }
};

const DevicesInformations = () => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  return (
    <div className={globalClasses.customArrayField}>
      <ArrayField source="devices">
        <Datagrid classes={classes}>
          <DeviceType source="type" />
          <DeviceName source="name" />
          <DateField source="created_at" label={t("buildings.show.devices_list.created_at")} />
          <DateField source="updated_at" label={t("buildings.show.devices_list.updated_at")} />
        </Datagrid>
      </ArrayField>
    </div>
  );
};

export default DevicesInformations;
