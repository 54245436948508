import React, { useState, useEffect } from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CustomDataProvider from "../dataProvider";
import Logo from "./Logo";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "white",
    fontSize: "1em",
  },
  spacer: {
    flex: 1,
  },
});

const UserWorspace = () => {
  const [workspace, setWorkspace] = useState();

  useEffect(() => {
    CustomDataProvider.getOne("users", { id: "me" })
      .then((data) => setWorkspace(data.data.workspaces[0]))
  }, []);

  return (
    <Typography variant="h3">{workspace ? workspace : "VOLTYO"}</Typography>
  );
};

const CustomAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar {...props}>
      <Logo />
      <Typography
        className={classes.title}
        id="react-admin-title"
      />
      <UserWorspace />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
