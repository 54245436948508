import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import { useTranslate } from 'react-admin';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '10px',
        flexDirection: 'column',
        height: "550px",
    },
    image: {
        display: 'flex',
        padding: '25px',
        justifyContent: 'center',
    },
    footer : {
        padding: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
    }
}));

const NoData = (props) => {
    const classes = useStyles();
    const t = useTranslate();

    return (
        <div className={classes.container}>
            <div className={classes.image}>
                <FontAwesomeIcon icon={faEmptySet}size="2xl"/>
            </div>
            <div className={classes.footer}>
                {props.footer}
            </div>
        </div>
    )
}

export default NoData
