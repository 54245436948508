import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ReactECharts from 'echarts-for-react';

const useStyles = makeStyles((theme) => ({
    chartBar: {
        height: "100%",
        "& > *": {
        }
    }
}));

const ChartBar = (props) => {
    const classes = useStyles();
    const series_set = props.data.map( v => v.series)
    const ids_set = props.data.map( v => v.device_id)

    const option = {
        title: {
            text: props.title
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
            type: 'shadow'
            }
        },
        toolbox: {
            feature: {
              saveAsImage: {}
           }
        },
        legend: {},
        grid: {
            left: '10%',
            right: '15%',
            bottom: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            name: 'Consommation (kW)',
            nameLocation: 'middle',
            nameGap: 40,
            axisLabel: {}
        },
        series: series_set.map( curve => {
                                const data = curve.map(v => v.value );
                                return { name: ids_set[0],
                                         type: 'bar',
                                         data: data
                                     }
                })
        };

    return (
        <div className={classes.chartBar}>
            <ReactECharts
                notMerge={true}
                option={option}
                style={{height: '100%', width: '100%'}} />
        </div>
    )
}

export default ChartBar
