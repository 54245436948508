// Libs
import React, { useState, useEffect } from "react";
import {
  TextField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  useTranslate,
  useRecordContext,
  BooleanField,
} from "react-admin";

// Material UI components
import { Typography } from "@material-ui/core";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

// Dataprovider
import CustomDataProvider from "../../dataProvider";

// Helpers components
import {
  AlertStrategy,
  CheckInterval,
  NotificationInterval,
  DeviceProperty,
  ThresholdValueAndUnit,
  NotificationStrategy,
} from "./ShowHelpers";

const AlertShowDetails = () => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  // Get devices properties to display in needed helpers functions
  const record = useRecordContext();
  const [selectedDevice, setSelectedDevice] = useState();
  const [building, setBuilding] = useState();
  const [deviceProperty, setDeviceProperty] = useState([]);

  useEffect(() => {
    CustomDataProvider.getOne("devices", { id: record.check.device }).then(
      (data) => setSelectedDevice(data.data)
    );
  }, [record.check.device]);

  useEffect(() => {
    if (selectedDevice) {
      CustomDataProvider.getOne("buildings", {
        id: selectedDevice.parent,
      }).then((data) => setBuilding(data.data));
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (selectedDevice) {
      const deviceProperties = selectedDevice.properties;
      setDeviceProperty(
        deviceProperties.filter(
          (property) => property.id === record.check.property
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.general.name")}</Typography>
            <TextField source="name" className={globalClasses.customTextField} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.general.active")}</Typography>
            <BooleanField
              source="is_active"
              className={globalClasses.customReferenceArrayField}
            />
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">{t("alerts.data.title")}</Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.data.device")}</Typography>
            {building ? (
              <span className={globalClasses.customSpanField}>
                {selectedDevice.name} - {building.name}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.data.type")}</Typography>
            <DeviceProperty deviceProperty={deviceProperty} />
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">{t("alerts.trigger.title")}</Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.trigger.type")}</Typography>
            <AlertStrategy />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.trigger.value")}</Typography>
            <ThresholdValueAndUnit
              record={record}
              deviceProperty={deviceProperty}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.trigger.check")}</Typography>
            <CheckInterval />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("alerts.trigger.reminder")}</Typography>
            <NotificationInterval />
          </div>
        </div>
      </div>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">{t("alerts.notification.title")}</Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">
              {t("alerts.notification.user")}
            </Typography>
            <ReferenceArrayField
              reference="users"
              source="notification.users"
              className={globalClasses.customReferenceArrayField}
            >
              <SingleFieldList>
                <ChipField
                  className={globalClasses.usersInAlertCustomChipField}
                  source="username"
                />
              </SingleFieldList>
            </ReferenceArrayField>
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">
              {t("alerts.notification.type")}
            </Typography>
            <NotificationStrategy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertShowDetails;
