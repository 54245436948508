import axiosInstance from '../../utils/axiosInstance';
import { sub, parseISO, formatISO } from 'date-fns';
import { Ls } from 'dayjs';



const getTimeseriesData = (props) => {
    let sources_tmp = [];
    var params = new URLSearchParams();

    params.append(
        "start_date", props.interval === -1 || !props.interval ? typeof props.start_date === 'string' ? props.start_date : formatISO(props.start_date) : formatISO(sub(typeof props.start_date === 'string' ? parseISO(props.start_date) : props.start_date, { weeks: props.interval })),
    );
    params.append(
        "end_date", props.interval === -1 || !props.interval ? typeof props.end_date === 'string' ? props.end_date : formatISO(props.end_date) : new Date().toISOString()
    );
    params.append(
        "aggregation_period", props.aggregation_period
    );
    params.append(
        "aggregation_type", props.aggregation_type
    );

    props?.sources.map((s) => {
        let sources = `{"type":"${s.type}","id":"${s.id}","property":"${s.property}"`;
        if (s.post_aggregate) {
            sources += ',"post_aggregate":['
            const post_aggregate = s.post_aggregate.map( pa => {
                sources += `{"category":"${pa.category}","operation":"${pa.operation}","value":${pa.value}},`;
            })
            sources = sources.slice(0, -1);
            sources += "]"
            sources_tmp.push({post_aggregate: post_aggregate});
        }
        sources += "}"
        params.append("sources", sources);
        sources_tmp.push({sources : s})
    })

    return axiosInstance.get('timeseries/', {
        params,
    });
}

export {getTimeseriesData}