const translateAlertsFr = {
  alerts: {
    title: {
      list: "Voir les alertes",
      create: "Ajouter une alerte",
      edit: "Editer l'alerte",
      show: "Voir l'alerte",
    },
    general: {
      name: "Nom de l'alerte",
      title: "Titre",
      active: "Alerte activée",
    },
    data: {
      title: "Données à monitorer",
      device: "Compteur ou capteur",
      type: "Type de données",
    },
    trigger: {
      title: "Conditions de déclenchement",
      type: "Type de valeur",
      value: "Montant de la valeur",
      check: "Vérification",
      reminder: "Rappel",
    },
    notification: {
      title: "Gestion des notifications",
      user: "Utilisateurs ou groupes",
      type: "Recevoir les alertes par",
    },
    form: {
      trigger: {
        strategy: {
          superior: "Supérieur à",
          inferior: "Inférieur à",
          equal: "Egal à",
        },
      },
      interval_in_minutes: {
        each_minute: "Chaque minute",
        each_5_minutes: "Chaque 5 minutes",
        each_15_minutes: "Chaque 15 minutes",
        each_30_minutes: "Chaque 30 minutes",
        each_hour: "Chaque heures",
        each_6_hours: "Chaque 6 heures",
        each_12_hours: "Deux fois par jour",
        each_day: "Chaque jour",
        each_week: "Chaque semaine",
        each_month: "Chaque mois",
      },
      notification: {
        type: {
          phone_text: "sms",
          email: "email",
        },
      },
    },
  },
};

export default translateAlertsFr;
