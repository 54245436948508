// components
import BuildingMap from "../map/BuildingMap";
import { Field } from "react-final-form"

const BuildingLocalisation = (props) => {

  return (
    <div>
      <Field name='fullAddress'>
        { props => (
          <BuildingMap
            value={props.input.value}
            onChange={props.input.onChange}
          />
        )}
      </Field>
    </div>
  );
};

export default BuildingLocalisation;
