// Libs
import React from "react";
import { Show, useTranslate, SimpleShowLayout } from "react-admin";

// Components
import AlertShowDetails from "./AlertShowDetails";


const AlertShow = (props) => {
  const t = useTranslate();

  return (
    <Show title={t("alerts.title.show")} {...props}>
      <SimpleShowLayout>
        <AlertShowDetails />
      </SimpleShowLayout>
    </Show>
  );
};

export default AlertShow;
