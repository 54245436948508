const translateResourcesFr = {
  resources: {
    global: {
      save: "Enregistrer",
      add : "Ajouter",
      remove : "Supprimer"
    },
    home: {
      name: "Accueil",
    },
    buildings: {
      name: "Bâtiments",
      fields: {
        name: "Nom",
        surface: "Surface",
        address: "Adresse",
        city: "Ville",
        country: "Pays",
        zipcode: "Code Postal",
        construction_year: "Année de construction",
      },
    },
    alerts: {
      name: "Alertes",
      fields: {
        name: "Nom",
        is_active: "Actif",
        check: {
          device: "Equipements",
        },
        notification: {
          users: "Utilisateurs",
          last_notified_at: "Dernière alerte",
        },
      },
    },
    anomalies: {
      name: "Anomalies",
      fields: {
        name: "Bâtiment",
        viewed: "Vue",
        timestamp: "Data et l'heure",
      },
    },
    reports: {
      name: "Rapport",
      fields: {
        building_name: "Nom du bâtiment",
        title: "Titre",
        created_at: "Date de création",
      },
    },
    contracts: {
      name: "Contrats",
      fields: {
        building_name: "Nom du bâtiment",
        device_name: "Nom de l'appareil",
        maximum_power_value: "Puissance souscrite",
        created_at: "Date de création",
        updated_at: "Dernière modification",
      },
    },
    billings: {
      name: "Factures",
      fields: {
        building_name: "Nom du bâtiment",
        device_name: "Mon de l'équipement",
        supplier: "Fournisseur",
        amount: "Valeur",
        date: "Date",
      },
      show: {
        title: "Afficher les factures",
      },
    },
    users: {
      name: "Utilisateurs",
      fields: {
        username: "Nom d'utilisateur",
        email: "Mail",
        workspaces: "Espaces de travail",
        roles: "Rôles",
        activated: "Actif",
      },
    },
    devices: {
      name: "Equipements",
      fields: {
        title: "Équipements",
        name: "Nom",
        parent: "Bâtiment",
        type: "Type",
        heater: "Chauffage",
        cooler: "Climatisation",
      },
    },
    dashboards: {
      name: "Tableaux de bords",
      fields: {
        name: "Nom",
        created_at: "Créé le",
        updated_at: "Dernière mise à jour le",
      },
    },
  },
};

export default translateResourcesFr;
