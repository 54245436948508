// Libs
import React, { useCallback, useState } from "react";
import {
  Button,
  Edit,
  ShowButton,
  TopToolbar,
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate,
} from "react-admin";

// Components
import ChartPreview from "./ChartPreview";
import ChartsForm from "./ChartsForm";

// MUI
import ContentAdd from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";

// Grid Layout Components
import GridLayout from "react-grid-layout";
import "../../styles/react-grid-layout.scss";
import { cloneDeep } from "lodash";

// Custom Style
import { faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalUseStyles from "../../styles/globalCustomStyles";

const DashboardsEditActions = (props) => {
  const [update, { isLoading, error }] = useUpdate();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const notify = useNotify();

  const { basePath = "", data, resource, scrollToTop = true, ...any } = props;
  const record = data;

  const openCreateDialog = () => {
    setShowCreateDialog(true);
  };

  const handleCloseClick = () => {
    setShowCreateDialog(false);
  };

  const addGraph = (data) => {
    let editedGraphs = record.graphs?.length > 0 ? record.graphs : [];
    editedGraphs.push({
      ...data,
      layout: {
        x: 0,
        y: 0,
        width: 4,
        height: 8,
      },
    });
    update("dashboards", record.id, { graphs: editedGraphs }, record, {
      onFailure: (err) => {
        notify("dashboards.server_error", "warning");
      },
    });
    setShowCreateDialog(false);
  };

  return (
    <TopToolbar>
      <div className={globalClasses.dashboards_buttonBar}>
        <ShowButton record={record} />
        <Button
          onClick={openCreateDialog}
          label={t("dashboards.add_graph")}
          {...any}
        >
          <ContentAdd />
        </Button>
        <Dialog
          disableEnforceFocus
          fullWidth
          open={showCreateDialog}
          onClose={handleCloseClick}
        >
          <div style={{ height: "100%" }} className="chart-form">
            <ChartsForm
              onSave={addGraph}
              onCancel={() => setShowCreateDialog(false)}
            />
          </div>
        </Dialog>
      </div>
    </TopToolbar>
  );
};

const GridItems = (props) => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();
  const graphs = record.graphs;
  const [update, { isLoading, error }] = useUpdate();
  const layout = graphs?.map((graph) => ({
    i: graph.name,
    x: graph.layout.x,
    y: graph.layout.y,
    w: graph.layout.width,
    h: graph.layout.height,
  }));
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editedGraph, setEditedGraph] = useState({});
  const notify = useNotify();

  const handleCloseClick = useCallback(() => setShowEditDialog(false), []);

  const onLayoutChange = (layout) => {
    let editedGraphs = [];
    layout.forEach((lay) => {
      let editedGraph = record?.graphs.find((graph) => graph.name == lay.i);
      if (editedGraph) {
        editedGraph.layout = {
          x: lay.x,
          y: lay.y,
          width: lay.w,
          height: lay.h,
        };
        editedGraphs.push(editedGraph);
      }
    });

    update("dashboards", record.id, { graphs: editedGraphs }, record, {
      onFailure: (err) => {
        notify("dashboards.server_error", "warning");
      },
    });
  };

  const editGraph = (editedForm) => {
    let editedIdx = record?.graphs.findIndex(
      (graph) => graph.name == editedGraph.name
    );
    record.graphs[editedIdx] = {
      ...editedForm,
      layout: record.graphs[editedIdx].layout,
    };
    update("dashboards", record.id, { graphs: record.graphs }, record, {
      onFailure: (err) => {
        notify("dashboards.server_error", "warning");
      },
    });
    setShowEditDialog(false);
  };

  const deleteGraph = (dGraph) => {
    let deletedGraphIdx = record?.graphs.findIndex(
      (graph) => graph.name == dGraph
    );
    if (deletedGraphIdx > -1) {
      record?.graphs.splice(deletedGraphIdx, 1);
      update("dashboards", record.id, { graphs: record.graphs }, record);
    }
  };

  const openEditForm = (graph) => {
    setEditedGraph(cloneDeep(graph));
    setShowEditDialog(true);
  };

  return (
    <>
      <GridLayout
        onLayoutChange={onLayoutChange}
        className="layout"
        layout={layout}
        cols={8}
        rowHeight={40}
        width={1200}
        compactType={"vertical"}
        isResizable={true}
      >
        {graphs?.length > 0 &&
          graphs?.map((graph) => {
            return (
              <Card className={globalClasses.dashboards_ItemCard} key={graph.name}>
                <div className={globalClasses.dashboards_ItemContainer}>
                  <div className={globalClasses.dashboards_ItemToolBar}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteGraph(graph.name);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan} size="lg" />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        openEditForm(graph);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} size="lg" />
                    </Button>
                  </div>
                  <div className={globalClasses.dashboards_GraphContainer}>
                    <ChartPreview props={graph} />
                  </div>
                </div>
              </Card>
            );
          })}
      </GridLayout>
      <Dialog
        disableEnforceFocus
        fullWidth
        open={showEditDialog}
        onClose={handleCloseClick}
      >
        <div style={{ height: "100%" }}>
          <ChartsForm
            editedGraph={editedGraph}
            onSave={editGraph}
            onCancel={() => setShowEditDialog(false)}
          />
        </div>
      </Dialog>
    </>
  );
};

const DashboardsEdit = (props) => {

  return (
    <Edit
      actions={<DashboardsEditActions {...props} />}
      {...props}
    >
      <GridItems />
    </Edit>
  );
};

export default DashboardsEdit;
