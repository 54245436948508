// Libs
import React from "react";
import { TextInput, useTranslate } from "react-admin";

// Material UI components
import { Typography } from "@material-ui/core";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

const DashboardsForm = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
          {t("dashboards.create.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput
              source="name"
              fullWidth="true"
              label={t("dashboards.create.name")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardsForm;
