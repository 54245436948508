// Libs
import React from 'react';
// import { useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
// Components
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import ToggleButton from '@mui/lab/ToggleButton';
// Context
import Tooltip from '@material-ui/core/Tooltip';

// Icons
// import CurveGraphIcon from '../../../assets/curve.svg';
// import BarGraphIcon from '../../../assets/Bar.svg';
// import Histogram from '../../../assets/histogram.svg';

// FortAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { faCalendar, faChartColumn } from '@fortawesome/pro-regular-svg-icons';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons';
import { faChartPyramid } from '@fortawesome/pro-regular-svg-icons';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { faChartArea } from '@fortawesome/pro-regular-svg-icons';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons';

import { faDroplet } from '@fortawesome/pro-regular-svg-icons';
import { faFireFlameCurved } from '@fortawesome/pro-regular-svg-icons';
import { faBolt } from '@fortawesome/pro-regular-svg-icons';

import { faMoon  } from '@fortawesome/pro-regular-svg-icons';
// import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons';



const Icons = {
    ChartColumn: faChartColumn,
    ChartBar: faChartBar ,
    ChartLine: faChartLine,
    ChartStack: faChartPyramid ,
    ChartArea: faChartArea,
    ChartMixed: faChartMixed,
    Water: faDroplet,
    Gaz: faFireFlameCurved,
    Electricity: faBolt,
    Calendar: faCalendar,
    Nights: faMoon
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
    },
    selected : props => ({
        "&.Mui-selected": {
            borderColor: props.borderColor +'!important',
            borderWidth: '2px!important',
            backgroundColor: props.backgroundColor + '!important'
        }
    })
}));


const SelectButtons = props => {
    const selectStyle = {borderColor: props.borderColor, backgroundColor: props.backgroundColor};
    const classes = useStyles(selectStyle);
    // const t = useTranslate();

    console.log(classes.selected)
    return (
        <div className={classes.container}>
            <ToggleButtonGroup
                value={props.focusValue}
                exclusive
                onChange={({ currentTarget: { value } }) => {
                    props.changeState(value);
                }}
                orientation={props.orientation}
            >
                {props.buttons.map(button => (
                    button.tooltip ?
                        <Tooltip
                            placement={button.tooltip.position}
                            key={button.type}
                            title={button.tooltip.text}
                        >
                            <ToggleButton
                                key={button.type}
                                value={button.type}
                                disabled={button.disabled ? true : false}
                                selected={button.type === props.focusValue}
                                classes={{ selected: classes.selected }}
                            >
                               <FontAwesomeIcon icon={Icons[button.icon]} size="lg" />
                            </ToggleButton>
                        </Tooltip>
                        :
                        <ToggleButton
                            key={button.type}
                            value={button.type}
                            disabled={button.disabled ? true : false}
                            classes={{ selected: classes.selected }}
                        >
                            {
                            button.text ?
                                button.text
                                :
                                <FontAwesomeIcon icon={Icons[button.icon]}size="lg"/>
                            }
                        </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
};

export default SelectButtons;
