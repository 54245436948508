// Libs
import React from "react";
import {
  TextInput,
  BooleanInput,
  SelectInput,
  email,
  useTranslate,
  useRecordContext
} from "react-admin";
import { useField } from "react-final-form";

// Material UI components
import { Typography } from "@material-ui/core";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

// React Phone Input Components
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const PhoneNumberInput = ({ name, label }) => {
  const globalClasses = globalUseStyles();
  const {
    input: { onChange },
    meta: { touched, error },
    isRequired,
  } = useField(name);

  const record = useRecordContext()
  
  return (
    <PhoneInput
      country={"fr"}
      name={name}
      label={label}
      value={record ? record.phone_number : ""}
      onChange={onChange}
      containerClass={globalClasses.customPhoneContainer}
      inputClass={globalClasses.customPhoneInput}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
    />
  );
};

const UserEditForm = () => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const validateEmail = email();

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput source="username" label={t("users.show.username")} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <SelectInput
              source="role"
              label={t("users.show.role")}
              choices={[
                { id: "admin", name: "admin" },
                { id: "user", name: "user" },
              ]}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
          <PhoneNumberInput
              name="phone_number"
              label={t("users.show.phonenumber")}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput disabled source="email" label={t("users.show.mail")} validate={validateEmail} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <TextInput disabled label={t("users.show.workspace")} source="workspaces" />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <BooleanInput source="activated" label={t("users.show.activated")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditForm;
