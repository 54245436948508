// Libs
import React from "react";
import {
  Show,
  SimpleShowLayout,
  useTranslate,
} from "react-admin";

// Components
import DeviceShowDetails from "./DeviceShowDetails";

const DeviceShow = (props) => {
  const t = useTranslate();

  return (
    <Show title={t("users.show.title")} {...props}>
      <SimpleShowLayout>
        <DeviceShowDetails />
      </SimpleShowLayout>
    </Show>
  );
};

export default DeviceShow;
