// Lib
import React from "react";
import { Show, useTranslate, SimpleShowLayout } from "react-admin";

// Components
import Overview from "./BuildingShow/overview";

const BuildingShow = (props) => {
  const t = useTranslate();

  return (
    <Show title={t("buildings.show.title.main")} {...props}>
      <SimpleShowLayout>
        <Overview props={props}/>
      </SimpleShowLayout>
    </Show>
  );
};

export default BuildingShow;
