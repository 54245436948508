// Libs
import React, { useState, useEffect } from "react";
import { FilterForm, FilterButton, AutocompleteInput, useTranslate } from "react-admin";
import { Stack } from "@mui/material";

// Components
import CustomCreateButton from "../../../styles/CustomButtons/CustomCreateButton";

// DataProvider
import CustomDataProvider from "../../../dataProvider";

const BuildingListActions = (props) => {
  const t = useTranslate();
  const label = t("buildings.create.title")

  const [buildings, setBuildings] = useState([]);
  const [names, setNames] = useState([]);
  const [cities, setCities] = useState([]);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    CustomDataProvider.getMany("buildings", {}).then((data) =>
      setBuildings(data.data)
    );
  }, []);

  useEffect(() => {
    setNames(
      buildings.map((building) => ({ id: building.id, name: building.name }))
    );
    setCities(
      buildings.map((building) => ({ id: building.id, name: building.city }))
    );
    setAddresses(
      buildings.map((building) => ({ id: building.id, name: building.address }))
    );
  }, [buildings]);

  const buildingFilters = [
    <AutocompleteInput source="name" choices={names} />,
    <AutocompleteInput source="address" choices={addresses} />,
    <AutocompleteInput source="city" choices={cities} />,
  ];
  return (
    <div style={{width: '100%'}}>
      <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={buildingFilters} />
        <div>
          <FilterButton
            filters={buildingFilters}
          />
          <CustomCreateButton props={props} label={label} />
        </div>
      </Stack>
    </div>
  );
};

export default BuildingListActions;
