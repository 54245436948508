// Libs
import React from "react";
import { Create, useTranslate } from "react-admin";

// Components
import { SimpleForm } from "react-admin";

// Components
import CustomRegistrationToolbar from "../../components/CustomToolbars/CustomRegistrationToolbar";
import DashboardsForm from "./DashboardsForm";

const DashboardsCreate = (props) => {
  const t = useTranslate();

  return (
    <Create title={t("buildings.create.title")} {...props}>
      <SimpleForm toolbar={<CustomRegistrationToolbar />}>
        <DashboardsForm props={props} />
      </SimpleForm>
    </Create>
  );
};

export default DashboardsCreate;
