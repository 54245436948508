// Libs
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  SaveButton,
  useTranslate,
  useRedirect,
} from "react-admin";
import { useFormState } from "react-final-form";

// Components
import Button from "@material-ui/core/Button";

// Multistepform custom design
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
    margin: "2rem auto",
    [theme.breakpoints.down(980)]: {
      width: '90%',
    },
  },
  button: {
    background: "none",
    padding: "8px 18px",
    color: "black",
    fontWeight: "bold",
    boxShadow: "none",
    borderRadius: "25px",
    "&:hover": {
      color: "black",
      background: "#f4f4f4",
      borderRadius: "25px",
      boxShadow: "none",
    },
  },
  cancelButton: {
    background: "none",
    padding: "8px 18px",
    color: "#a70000",
    fontWeight: "bold",
    boxShadow: "none",
    borderRadius: "25px",
    "&:hover": {
      color: "#a50000",
      background: "#fad1d1",
      borderRadius: "25px",
      boxShadow: "none",
    },
  },
  endScreenButton: {
    background: "#E89712",
    padding: "8px 18px",
    borderRadius: "25px",
    color: "white",
    fontWeight: "bold",
    boxShadow: "1px 2px 2px rgb(128, 128, 128, .5)",
    "&:hover": {
      background: "#F69F10",
    },
  },
}));

const CustomToolbar = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const redirect = useRedirect();
  const { values } = useFormState();

  // Form filling state
  const [completedStep, setCompletedStep] = useState(false);

  const handleNext = () => {
    if (completedStep === true) {
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCancel = () => {
    redirect(`${props.basePath}`);
  };

  // Form filling management
  useEffect(() => {
    if (props.activeStep === 0 && values.name && values.surface) {
      return setCompletedStep(true);
    } else if (props.activeStep === 1 && values.fullAddress) {
      return setCompletedStep(true);
    } else if (props.activeStep === 2) {
      return setCompletedStep(true);
    } else {
      return setCompletedStep(false);
    }
  }, [props.activeStep, values.name, values.surface, values.fullAddress]);

  return (
      <div>
        <div className={classes.buttonContainer}>
          <Button className={classes.cancelButton} onClick={handleCancel}>
            {t("buildings.create.form.cancel")}
          </Button>
          <Button
            disabled={props.activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            {t("buildings.create.form.previous")}
          </Button>
          {props.activeStep === props.steps.length - 1 ? (
            <SaveButton
              handleSubmitWithRedirect={props.handleSubmitWithRedirect}
              className={classes.endScreenButton}
              label={t("buildings.create.form.save")}
              redirect="list"
              variant="text"
            />
          ) : (
            <Button
              disabled={completedStep === false}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {t("buildings.create.form.next")}
            </Button>
          )}
        </div>
      </div>
  );
};

export default CustomToolbar;
