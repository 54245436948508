// In theme.js
import { defaultTheme } from "react-admin";
import { createTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";

const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: "#020202", // Not far from red
      },
      secondary: {
        main: "#e89712", // Not far from orange
      },
    },
    typography: {
      h1: {
        textAlign: "center",
        color: "#E89712",
        fontSize: "3em",
        fontWeight: "bold",
        padding: "1rem 0 2rem",
      },
      h2: {
        textAlign: "center",
        fontSize: "1.7em",
        fontWeight: "bold",
        padding: "0.7rem 0",
      },
      h3: {
        textAlign: "center",
        fontSize: "1.5em",
        fontWeight: "bold",
        margin: "1rem 0",
      },
      h4: {
        fontSize: "1em",
        fontWeight: "bold",
        margin: "0.5em 1em",
      },
      h5: {
        marginTop: "1em",
      },
      h6: {
        fontSize: "1em",
        alignSelf: "start",
      },
    },
    overrides: {
      RaCreate: {
        card: {
          backgroundColor: "#FAFAFA",
          boxShadow: "none",
        },
      },
      RaEdit: {
        card: {
          backgroundColor: "#FAFAFA",
          boxShadow: "none",
        },
      },
      RaShow: {
        card: {
          backgroundColor: "#FAFAFA",
          boxShadow: "none",
        },
      },
      RaDatagrid: {
        headerCell: {
          height: "3rem",
          fontWeight: "bold",
          color: "#E89712",
          fontSize: "1.2rem",
        },
        row: {
          height: "3rem",
          "&:nth-child(2n)": {
            backgroundColor: "rgba(235, 235, 235, .3)",
          },
        },
      },
      RaButton: {
        button: {
          background: "#E89712 !important",
          padding: "0.7rem !important",
          margin: "0.5rem !important",
          borderRadius: "25px !important",
          color: "white !important",
          textAlign: "right !important",
          fontWeight: "bold !important",
          boxShadow: "1px 2px 2px rgb(128, 128, 128, .5)",
          "&:hover": {
            background: "#F69F10 !important",
          },
        },
      },
      RaSaveButton: {
        button: {
          background: "#E89712 !important",
          padding: "0.7rem !important",
          margin: "0.5rem !important",
          borderRadius: "25px !important",
          color: "white !important",
          textAlign: "right !important",
          fontWeight: "bold !important",
          boxShadow: "1px 2px 2px rgb(128, 128, 128, .5)",
          "&:hover": {
            background: "#F69F10 !important",
          },
        },
      },
    },
  })
);

export { theme };
