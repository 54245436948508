// Libs
import React, { useState } from "react";
import { Button, useRecordContext, Show, useTranslate } from "react-admin";

// React Grid Layout
import GridLayout from "react-grid-layout";
import "../../styles/react-grid-layout.scss";

// Components
import ChartPreview from "./ChartPreview";
import GraphCreateButton from "./CustomButtons/GraphCreateButton";

// MUI
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GridItems = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const dash = useRecordContext();
  const [showFullScreen, setShowFullScreen] = useState(false);
  const graphs = dash.graphs;
  const layout = graphs?.map((graph) => ({
    i: graph.name,
    x: graph.layout.x,
    y: graph.layout.y,
    w: graph.layout.width,
    h: graph.layout.height,
    static: true,
    isResizable: false,
  }));
  const [selectedGraph, setSelectedGraph] = useState({});
  console.log(dash);

  const showFullScreenGraph = (graph) => {
    setSelectedGraph(graph);
    setShowFullScreen(true);
  };

  const handleCloseClick = () => {
    setShowFullScreen(false);
  };

  return graphs ? (
    <div>
      <GridLayout
        className="layout"
        layout={layout}
        cols={8}
        rowHeight={40}
        width={1200}
        compactType={"vertical"}
        isResizable={true}
      >
        {graphs?.length > 0 &&
          graphs?.map((graph) => {
            return (
              <Card className={globalClasses.dashboards_ItemCard} key={graph.name}>
                <div className={globalClasses.dashboards_ItemContainer}>
                  <div className={globalClasses.dashboards_ItemToolBar}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        showFullScreenGraph(graph);
                      }}
                    >
                      <FontAwesomeIcon icon={faExpand} size="lg" />
                    </Button>
                  </div>
                  <div className={globalClasses.dashboards_GraphContainer}>
                    <ChartPreview props={graph} />
                  </div>
                </div>
              </Card>
            );
          })}
      </GridLayout>
      <Dialog
        disableEnforceFocus
        fullWidth
        open={showFullScreen}
        onClose={handleCloseClick}
      >
        <div style={{ height: "100%" }}>
          <ChartPreview
            props={selectedGraph}
            onCancel={() => setShowFullScreen(false)}
          />
        </div>
      </Dialog>
    </div>
  ) : (
    <div className={globalClasses.dashboards_EmptyCard}>
      <div style={{display: "flex", justifyContent: "center", margin: "auto"}}>
        <svg
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          width="100%"
        >
          <path
            style={{ fill: "#8D8D8D", height: "100%", width: "100%" }}
            d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10z"
          ></path>
        </svg>
      </div>
      <p style={{ color: "#8D8D8D", fontWeight: "bold" }}>
        {t("dashboards.show.no_graphic")}
      </p>
      <p style={{ color: "#8D8D8D" }}>{t("dashboards.show.create_one")}</p>
      <GraphCreateButton record={dash} props={props} />
    </div>
  );
};

const DashboardsShow = (props) => {
  const t = useTranslate();

  return (
    <Show {...props} title={t("dashboards.show.title")}>
      <div>
        <GridItems />
      </div>
    </Show>
  );
};

export default DashboardsShow;
