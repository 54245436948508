// Libs
import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
// Context
import SwitchButton from "./SwitchButton"
import "../style.css"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "5px 5px 0px 5px"
    },
    TitleContainer : {
        padding: "5px 5px 2px 5px",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize
    },
    ButtonsContainer : {
        borderRadius: "10px",
        border: "2px solid #E5E5E5",
        width: "200px",
        height: "60px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'}
}));

const AnalyticsCorrections = (props) => {
    const classes = useStyles();
    const t = useTranslate()

    return (
        <div className={classes.container}>
            <div className={classes.TitleContainer}>
                {props.title}
            </div>
            <div className={classes.ButtonsContainer}>
                <SwitchButton
                    value={props.surfaceCorrection}
                    onChange={props.onChangeSurfaceCorrection}
                    onText={t("buildings.show.analytics.toolbar.correction.surface")}
                    offText={t("buildings.show.analytics.toolbar.correction.surface")}
                />
                <SwitchButton
                    value={props.weatherCorrection}
                    onChange={props.onChangeWeatherCorrection}
                    offText={t("buildings.show.analytics.toolbar.correction.weather")}
                    onText={t("buildings.show.analytics.toolbar.correction.weather")}
                    disabled={true}
                />
            </div>
        </div>
    );
};


export default AnalyticsCorrections;