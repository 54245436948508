import frenchMessages from "ra-language-french";
import translateResourcesFr from "./translateResourcesFr";
import translateHomeFr from "./translateHomeFr";
import translateDashboardFr from "./translateDashboardFr";
import translateAlertsFr from "./translateAlertsFr";
import translateAnalyticsFr from "./translateAnalyticsFr";
import translateAnomaliesFr from "./translateAnomaliesFr";
import translateBillingsFr from "./translateBillingsFr";
import translateBuildingsFr from "./translateBuildingsFr";
import translateDashboardsFr from "./translateDashboardsFr";
import translateDevicesFr from "./translateDevicesFr";
import translateUsersFr from "./translateUsersFr";

const customFrenchMessages = {
  ...frenchMessages,
  ...translateResourcesFr,
  ...translateHomeFr,
  ...translateDashboardFr,
  ...translateAlertsFr,
  ...translateAnalyticsFr,
  ...translateAnomaliesFr,
  ...translateBillingsFr,
  ...translateBuildingsFr,
  ...translateDashboardsFr,
  ...translateDevicesFr,
  ...translateUsersFr,
};

export default customFrenchMessages;
