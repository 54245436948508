// Libs
import React from "react";
import {
  TextField,
  ChipField,
  BooleanField,
  EmailField,
  useTranslate,
  useRecordContext,
} from "react-admin";

// Material UI components
import { Typography } from "@material-ui/core";

// Custom Style
import globalUseStyles from "../../styles/globalCustomStyles";

// React Phone Input Components
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const PhoneNumberInput = () => {
  const globalClasses = globalUseStyles();
  const record = useRecordContext();

  return (
    <PhoneInput
      disabled
      country={"fr"}
      value={record ? record.phone_number : ""}
      containerClass={globalClasses.customPhoneContainer}
      inputClass={globalClasses.customPhoneField}
    />
  );
};

const UserShowDetails = () => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  return (
    <div className={globalClasses.globalContainer}>
      <div className={globalClasses.cardContainer}>
        <div className={globalClasses.cardLeftPart}>
          <Typography variant="h4">
            {t("buildings.show.title.general_informations")}
          </Typography>
        </div>
        <div className={globalClasses.cardRightPart}>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("users.show.username")}</Typography>
            <TextField source="username" className={globalClasses.customTextField} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("users.show.role")}</Typography>
            <TextField source="role" className={globalClasses.customTextField} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("users.show.phonenumber")}</Typography>
            <PhoneNumberInput
              name="phonenumber"
              label={t("users.show.phonenumber")}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("users.show.mail")}</Typography>
            <EmailField source="email" className={globalClasses.customTextField} />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("users.show.workspace")}</Typography>
            <ChipField
              source="workspaces"
              className={globalClasses.customChipField}
            />
          </div>
          <div className={globalClasses.insideCardRightPart}>
            <Typography variant="h6">{t("users.show.activated")}</Typography>
            <BooleanField
              source="activated"
              className={globalClasses.customBooleanField}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserShowDetails;
