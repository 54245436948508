// Libs
import React, { useState } from "react";
import {
  Button,
  useNotify,
  useTranslate,
  useUpdate,
  usePermissions,
} from "react-admin";
import "../../../styles/react-grid-layout.scss";
import ContentAdd from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import ChartsForm from "../ChartsForm";

const GraphCreateButton = (props) => {
  const [update, { isLoading, error }] = useUpdate();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const t = useTranslate();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const { basePath = "", data, resource, scrollToTop = true, ...any } = props;
  const record = props.record;

  const openCreateDialog = () => {
    setShowCreateDialog(true);
  };

  const handleCloseClick = () => {
    setShowCreateDialog(false);
  };

  const addGraph = (data) => {
    let editedGraphs = record.graphs?.length > 0 ? record.graphs : [];
    editedGraphs.push({
      ...data,
      layout: {
        x: 0,
        y: 0,
        width: 4,
        height: 8,
      },
    });
    update("dashboards", record.id, { graphs: editedGraphs }, record, {
      onFailure: (err) => {
        notify("dashboards.server_error", "warning");
      },
    });
    setShowCreateDialog(false);
  };

  return (
    <div>
      {permissions === "admin" && (
        <>
          <Button
            onClick={openCreateDialog}
            label={t("dashboards.create.graph")}
            {...any}
          >
            <ContentAdd />
          </Button>
          <Dialog
            disableEnforceFocus
            fullWidth
            open={showCreateDialog}
            onClose={handleCloseClick}
          >
            <div style={{ height: "100%" }} className="chart-form">
              <ChartsForm
                onSave={addGraph}
                onCancel={() => setShowCreateDialog(false)}
              />
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default GraphCreateButton;
