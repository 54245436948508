// Libs
import React, { useEffect, useState } from 'react'
import { useGetList } from 'react-admin'
// Components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from 'react-admin'
import BasicSelect from '../../components/Input/BasicSelect';
import { TextField, Button, Divider } from '@mui/material';
import CalendarSelector from '../../components/buildings/Analytics/CalendarSelector';
import { format, parseISO } from 'date-fns'
import { getObjectToList } from '../../helpers/helper';
import MultipleSelect from '../../components/Input/MutipleSelect';
import ChartPreview from './ChartPreview';
import { Paper, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';

const useStyles = makeStyles((theme) => ({
    ChartForm: {
        backgroundColor: "#eee",
        height: "100%",
        "& .MuiSelect-select, .MuiInputBase-input": {
            padding: "6px !important",
            fontSize: "13px !important",
        },
        "& .MuiFormControl-root": {
            maxWidth: "200px",
            minWidth: "80px",
            margin: "8px",
            "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                transform: "translate(14px, 6px) scale(1)"
            }
        },
    },
    ToolBarContainer: {
        display: "flex",
        alignContent: "stretch",
        height: "100%",
        flexDirection: "column"
    },
    ChildContainer: {
        display: "flex",
        overflow: "auto",
        padding: '4px',
        margin: '4px'
    },
    DataSelectItem: {
        margin: '10px'
    },
    AggregationItem: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    endScreenButton: {
        padding: "0.7rem !important",
        margin: "0.5rem !important",
        borderRadius: "25px !important",
        fontWeight: "bold !important",
        boxShadow: "1px 2px 2px rgb(128, 128, 128, .5)",
        "&:not(.Mui-disabled):hover": {
            background: "#F69F10",
        },
        "&:not(.Mui-disabled)": {
            background: "#E89712",
            color: "white"
        }
    },
    SectionTitle: {
        fontWeight: 'bold',
        margin: '4px 0px'
    },
    AggregateOperationInput: {
        maxWidth: '110px !important',
        marginRight: '0px !important'
    },
    AggregateValueInput: {
        maxWidth: '80px !important'
    },
    buttonContainer: {
        display: "flex",
        width: "20%",
        justifyContent: "space-between",
        margin: "0.5rem auto",
        [theme.breakpoints.down(980)]: {
          width: '50%',
        },
      },
    cancelButton: {
        background: "none !important",
        padding: "0.7rem !important",
        margin: "0.5rem !important",
        color: "#a70000 !important",
        fontWeight: "bold !important",
        boxShadow: "none !important",
        borderRadius: "25px !important",
        "&:hover": {
          color: "#a50000 !important",
          background: "#fad1d1 !important",
          borderRadius: "25px !important",
          boxShadow: "none !important",
        },
    }
}));

const ChartTypeSelect = (props) => {
    const t = useTranslate();

    const choices = [
        { type: 'chart_column_bar', text: t("dashboards.chart_type.chart_column_bar"), icon: 'ChartColumn' },
        { type: 'chart_horizontal_bar', text: t("dashboards.chart_type.chart_horizontal_bar"), icon: 'ChartBar' },
        { type: 'chart_line', text: t("dashboards.chart_type.chart_line"), icon: 'ChartLine' },
        { type: 'number', text: t("dashboards.chart_type.number"), icon: 'ChartNumber' },
        { type: 'geomap', text: t("dashboards.chart_type.map"), icon: 'ChartMap' },
    ];
    return <BasicSelect id="type" label={t("dashboards.graphs.graph_type")} value={props.value} items={choices} onChange={(value) => { props.onChange(value) }} />
}

const AggregationPeriodSelect = (props) => {
    const t = useTranslate();

    const choices = [
        { type: "raw", text: t('dashboards.graphs.aggregation.period.raw') },
        { type: "hour", text: t('dashboards.graphs.aggregation.period.hour') },
        { type: "day", text: t('dashboards.graphs.aggregation.period.day') },
        { type: "week", text: t('dashboards.graphs.aggregation.period.week') },
        { type: "month", text: t('dashboards.graphs.aggregation.period.month') },
        { type: "year", text: t('dashboards.graphs.aggregation.period.year') },
    ];

    return <BasicSelect id="aggregation_period" label={t("dashboards.graphs.aggregate_period")} value={props.value} items={choices} onChange={(value) => {props.onChange(value) }} />
}

const AggregationTypeSelect = (props) => {
    const t = useTranslate();

    const choices = [
        { type: "raw", text: t('dashboards.graphs.aggregation.type.raw') },
        { type: "average", text: t('dashboards.graphs.aggregation.type.average') },
        { type: "sum", text: t('dashboards.graphs.aggregation.type.sum') },
    ];

    return <BasicSelect id="aggregation_type" label={t("dashboards.graphs.aggregate_type")} value={props.value} items={choices} onChange={(value) => {props.onChange(value) }} />
}

const PeriodSelect = (props) => {
    const t = useTranslate();

    const choices = [
        { type: true, text: t("dashboards.graphs.period_types.selected") },
        { type: false, text: t("dashboards.graphs.period_types.weeks") },
    ];

    return <div>
        <BasicSelect label={t("dashboards.graphs.period_type")} value={props.value} id="period" items={choices} onChange={(value) => props.onChange(value)} />
    </div>
}


const PostAggregationOperation = (props) => {
    const t = useTranslate();

    const choices = [
        { type: '/', text: t("dashboards.graphs.aggregation.operation.none") },
        { type: 'weather', text: t("dashboards.graphs.aggregation.operation.weather") },
    ];
    return <div>
        <BasicSelect className={props.className} label={t("dashboards.graphs.aggregate_operation")} value={props.value} id="post_aggregation.operation" items={choices} onChange={(value) => { props.onChange(value) }} />
    </div>
}

const PostAggregationCategory = (props) => {
    const t = useTranslate();

    const choices = [
        { type: 'none', text: t("dashboards.graphs.aggregation.category.none") },
        { type: 'arithmetic', text: t("dashboards.graphs.aggregation.category.arithmetic") },
        { type: 'functional', text: t("dashboards.graphs.aggregation.category.functional") },
    ];

    return <div>
        <BasicSelect label={t("dashboards.graphs.aggregate_category")} value={props.value} id="post_aggregation.category" items={choices} onChange={(value) => props.onChange(value)} />
    </div>
}

const DeviceSelect = (props) => {
    const [itemType, setItemType] = useState(props.value ? props.value : props.buildings[0]?.devices[0].id);
    const handleChange = (event) => {
        setItemType(event.target.value);
    };

    useEffect(() => {
        props.onChange(itemType)
    }, [itemType]);

    const getItems = (building) => {
        return building.devices.map((device) => <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>)
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl sx={{ m: 1, width: 200 }}>
                <InputLabel id="basic-select-label">{props.label}</InputLabel>
                <Select
                    labelId="basic-select-label"
                    id="basic-select"
                    value={itemType}
                    label={props.label}
                    onChange={handleChange}>
                    {props.buildings.map((building) => {
                        return [
                            <ListSubheader>
                                {building.name} :
                            </ListSubheader>,
                            getItems(building)
                        ]
                    }
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}

const SourcesSelect = (props) => {
    const t = useTranslate();
    const [selectedId, setSelectedId] = useState(props.value?.id);
    const handleOnChange = (data) => {
        props.onChange({ id: selectedId, properties: data, type: "number" })
    }

    if (!props.buildings) return <></>

    return <div>
        <DeviceSelect label={t("dashboards.graphs.device")} onChange={setSelectedId} value={props.value?.id} buildings={props.buildings ? getObjectToList(props.buildings) : []} />
        {selectedId &&
            <MultipleSelect label={t("dashboards.graphs.properties")} onChange={handleOnChange} selected={props.value?.properties?.length > 0 ? props.value?.properties : []} items={props.buildings ? getObjectToList(props.buildings)?.find(build => build.devices.findIndex((dev) => dev.id === selectedId) > -1)?.devices.find((dev) => dev.id === selectedId)?.properties.map((prop) => ({ id: prop.id, name: prop.name })) : []} />
        }
    </div>
}

const DataSelect = (props) => {
    const t = useTranslate();
    const classes = useStyles();
    const [datas, setDatas] = useState(props.value ?? [{ sources: {}, post_aggregate: { value: 0 }}]);
    const { data: buildings, loading, error } = useGetList('buildings', 
                                                    {page: 1, perPage: 150},
                                                    {field: "name", order: "ASC"},
                                                );

    const handleChange = (val, idx, attr) => {
        let newData = datas;
        newData[idx][attr] = val;
        props.onChange(newData);
    }

    const handleChangeAggregate = (val, idx, attr) => {
        let newData = datas;
        if (attr === 'category') {
            if (val === 'none') delete newData[idx]['post_aggregate']
            else if (val !== 'none' && newData[idx]['post_aggregate'] === undefined) {
                newData[idx]['post_aggregate'] = {value: 1, operation: '/', category: val}
            }
        } 
        if (newData[idx]['post_aggregate'] !== undefined) {
            newData[idx]['post_aggregate'][attr] = val;
        }
        props.onChange(newData);
    }

    const addItem = () => {
        setDatas(
            [...datas, { sources: {}}]
        )
    }

    if (loading || error) return <p></p>

    return <div className={classes.ToolBarContainer}>
        <Button className={classes.endScreenButton} onClick={addItem}>{t("dashboards.graphs.add_source")}</Button>
        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
            {datas.map((data, idx) => {
                return <div key={idx}>
                    <div className={classes.DataSelectItem}>
                        <div>
                            <Typography variant="h6">{t('dashboards.graphs.data_source')}</Typography>
                            <SourcesSelect buildings={buildings} value={data?.sources} onChange={(val) => handleChange(val, idx, 'sources')} />
                        </div>
                        <Typography variant="h6">Aggregation</Typography>
                        <PostAggregationCategory value={data?.post_aggregate ? data?.post_aggregate?.category : 'none'} onChange={(val) => handleChangeAggregate(val, idx, 'category')} />
                        {data?.post_aggregate && data?.post_aggregate?.category && data?.post_aggregate?.category !== 'none' &&
                            <div className={classes.AggregationItem}>
                                <PostAggregationOperation className={classes.AggregateOperationInput} value={data?.post_aggregate?.operation} onChange={(val) => handleChangeAggregate(val, idx, 'operation')} />
                                <TextField type='number' className={classes.AggregateValueInput} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label={t('dashboards.graphs.aggregate_value')} defaultValue={data?.post_aggregate?.value ? data.post_aggregate.value : 1} onChange={(e) => handleChangeAggregate(e.target.value, idx, 'value')} />
                            </div>
                         }
                    </div>
                    <Divider></Divider>
                </div>
            })}
        </div>
    </div>
}

const ChartsForm = (props) => {
    const classes = useStyles();
    const t = useTranslate();
    const [chartName, setChartName] = useState(props.editedGraph?.name);
    const [chartType, setChartType] = useState(props.editedGraph?.type);
    const [aggregationPeriod, setAggregationPeriod] = useState(props.editedGraph?.aggregation_period ? props.editedGraph?.aggregation_period : "raw");
    const [aggregationType, setAggregationType] = useState(props.editedGraph?.aggregation_type ? props.editedGraph?.aggregation_type : "raw");
    const [startDate, setStartDate] = useState(props.editedGraph ? parseISO(props.editedGraph?.start_date) : parseISO(new Date().toISOString()));
    const [endDate, setEndDate] = useState(props.editedGraph ? parseISO(props.editedGraph?.end_date) : parseISO(new Date().toISOString()));
    const [interval, setInterval] = useState(props.editedGraph?.interval ? props.editedGraph?.interval : 1);
    const [isSelectedDates, setIsSelectedDates] = useState(props.editedGraph?.interval === -1);
    const [datas, setDatas] = useState(props.editedGraph?.data);
    const [modified, setModified] = useState(0);

    const onSave = () => {
        props.onSave({
            name: chartName,
            type: chartType,
            data: datas,
            start_date: startDate,
            end_date: endDate,
            interval: interval,
            aggregation_type: aggregationType,
            aggregation_period: aggregationPeriod
        })
    }

    const handleDataChange = (data) => {
        setDatas(data);
        setModified(Date.now());
    }

    const handlePeriodChange = (data) => {
        if (data === true) {
            setInterval(-1);
        }
        setIsSelectedDates(data);
    }

    return (
        <div className={classes.ChartForm}>
            <div className={classes.ToolBarContainer}>
                <Paper className={classes.ChildContainer}>
                    <div>
                        <TextField label={t("dashboards.graphs.title")} value={chartName} onChange={(e) => setChartName(e.target.value)} />
                    </div>
                    <div>
                        <ChartTypeSelect value={chartType} onChange={setChartType} />
                    </div>
                    <AggregationPeriodSelect value={aggregationPeriod} onChange={setAggregationPeriod} />
                    <AggregationTypeSelect value={aggregationType} onChange={setAggregationType} />
                    <PeriodSelect value={isSelectedDates} onChange={handlePeriodChange} />
                    {isSelectedDates &&
                        <div style={{ display: 'flex' }}>
                            <CalendarSelector
                                value={startDate}
                                title={format(startDate, "dd MMM yyyy")}
                                handleCalendarChange={setStartDate}
                            />
                            <CalendarSelector
                                value={endDate}
                                title={format(endDate, "dd MMM yyyy")}
                                handleCalendarChange={setEndDate}
                            />
                        </div>
                    }
                    {!isSelectedDates &&
                        <TextField type='number' inputProps={{min: 1}} defaultValue={interval === -1 ? 0 : interval} onChange={(e) => setInterval(parseInt(e.target.value))} />
                    }
                    <div>
                    </div>
                </Paper>
                <div className={classes.ChildContainer} style={{ flex: '1' }}>
                    <Paper>
                        <DataSelect value={datas} onChange={handleDataChange} />
                    </Paper>
                    <Paper style={{ width: '60%', flex: '1', marginLeft: "16px" }}>
                        {datas !== [] &&
                            <ChartPreview type={chartType} props={{ data: datas, type: chartType, start_date: startDate, end_date: endDate, modified: modified, interval: interval, aggregation_period: aggregationPeriod, aggregation_type: aggregationType }} />
                        }
                    </Paper>
                </div>
                <div className={classes.buttonContainer}>
                    <Button className={classes.cancelButton} onClick={() => props.onCancel()}>
                        {t("buildings.create.form.cancel")}
                    </Button>
                    <Button
                        onClick={onSave}
                        className={classes.endScreenButton} 
                        disabled={!chartName || chartName === ""}
                    >
                        {t("resources.global.save")}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ChartsForm;
