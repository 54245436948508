// Libs
import React from 'react';
// import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
// Context
import SelectButtons from './SelectButtons';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "5px 5px 0px 5px"
    },
    TitleContainer : {
        padding: "5px 5px 2px 5px",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize
    },
    ButtonsContainer : {

        borderRadius: "10px",
        border: "2px solid #E5E5E5",
        padding: "10px",
        width: "90px",
        height: "40px",
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center"}
}));

const AnalytcisHighLights = (props) => {
    const classes = useStyles();
    const t = useTranslate()
    // const [weekendHighlight, setWeekendHighlight] = useState(false);
    // const [nightsHighlight, setNightsHighlight] = useState(false);

    const weekendsButton = [
        {
            type: "weekends",
            icon: "Calendar",
            tooltip: { text: t("buildings.show.analytics.toolbar.highlights.tooltips.weekends"), position: 'top' },
            disabled: props.isWeekendsHighlightDisabled === true ? true : false
        },
    ];
    const nightsButton = [
        {
            type: "nights",
            icon: "Nights",
            tooltip: { text: t("buildings.show.analytics.toolbar.highlights.tooltips.nights"), position: 'top' },
            disabled: props.isNightsHighlightDisabled === true ? true : false
        }
    ]

    const onChangeIsWeekendHighlight = value => {
        props.onChangeIsWeekendHighlight(!props.isWeekendsHighlight);
    }
    const onChangeIsNightsHighlight = value => {
        props.onChangeIsNightsHighlight(!props.isNightsHighlight);
    }

    return (
        <div className={classes.container}>
            <div className={classes.TitleContainer}>
                {props.title}
            </div>
            <div className={classes.ButtonsContainer}>
                <SelectButtons
                    buttons={weekendsButton}
                    changeState={onChangeIsWeekendHighlight }
                    focusValue={props.isWeekendsHighlight ? 'weekends' : undefined }
                    orientation={"horizontal"}
                    backgroundColor={'#FDDA0D80'}
                />
                <SelectButtons
                    buttons={nightsButton}
                    changeState={onChangeIsNightsHighlight }
                    focusValue={props.isNightsHighlight ? 'nights' : undefined }
                    orientation={"horizontal"}
                    backgroundColor={'#ADBAE180'}
                    borderColor= {'#ADBAE1'}
                />
            </div>
        </div>
    );
};


export default AnalytcisHighLights;