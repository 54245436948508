// Libs
import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from 'react-admin'

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Graphics from '../../components/buildings/Graphics/Graphics';
import BuildingMap from '../../components/dashboard/map/BuildingsMap';

//Assets
import LoadingGif from '../../assets/loading.gif'
import { useGetList } from 'react-admin';
import { getObjectToList, toTimestamp } from '../../helpers/helper';
import { getTimeseriesData } from '../../components/timeseries/helpers';
import NoData from '../../components/buildings/Graphics/NoData';

const useStyles = makeStyles((theme) => ({
    loading: {
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    }
}));

const chartBaseOption = {
    xAxis: {
        type: 'time',
        boundaryGap: false
    },
    yAxis: {
        type: 'value'
    },
};


const ChartPreview = ({ props }) => {
    const classes = useStyles();
    const t = useTranslate();
    const [graphData, setDatas] = useState(undefined);
    const { data: buildings, loading, error } = useGetList('buildings', {page: 1, perPage: 150});
    const [selectedBuildings, setSelectedBuildings] = useState([]);

    useEffect(() => {
        if (props.data) {
            let sources = [];
            props?.data.map((data) => {
                data.sources?.properties?.map((prop) => {
                    let source = {type: "device", id: data.sources.id, property: prop};
                    if (data.post_aggregate) {
                        source = {...source, post_aggregate: [data.post_aggregate]}
                    }
                    sources.push(source);
                });
            });
            getTimeseriesData({...props, sources: sources}).then((data) => {
                setDatas(data?.data?.map((d) => {
                    d.series.sort(function(x, y){
                        return toTimestamp(x.timestamp) - toTimestamp(y.timestamp);
                    })
                    return ({...d, series: d.series.map((serie, idx) => {
                            return ({ timestamp: serie.timestamp, value: serie.value });
                        })
                    })
                }));
            });
        }
    }, [props.data, props.aggregation_period, props.aggregation_type, props.start_date, props.end_date, props.modified, props.interval])

    useEffect(() => {
        if (props.type === 'geomap' && buildings) {
            let builds = getObjectToList(buildings)
            let fil = builds?.filter((building) => {
                return building.devices.some(device => {
                    return props.data.findIndex((source) => source.sources.id === device.id) > -1
                })
            }).map(build => build.id);
            setSelectedBuildings(fil);
        }
    }, [props.type, props.data, props.modified, buildings]);

    const getSeriesLabel = (serie) => {
        let building = getObjectToList(buildings)?.find(build => build.devices.findIndex((dev) => dev.id === serie.device_id) > -1);
        let device = building?.devices.find((dev) => dev.id === serie.device_id);
        return `${building?.name}, ${serie.property_name}`
    }


    return <>
        {props.type === 'geomap' &&
            <div style={{ width: '100%', height: '100%' }}>
                <BuildingMap buildingIds={selectedBuildings} />
            </div>
        }
        {props.type !== 'geomap' && graphData &&
            <>
                {graphData.length > 0 ?
                    <div style={{ width: '100%', height: '100%' }}>
                        {props.type !== 'geomap' && props.type !== 'number' && props.type &&
                            <Graphics
                                data={graphData.map((item, idx) => ({ ...item, name: getSeriesLabel(item) }))}
                                type={props.type}
                                title={props.name}
                                options={chartBaseOption}
                            />
                        }
                        {props.type === 'number' &&
                            <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                                <Typography gutterBottom variant="h4" component="p">
                                    {props.name}
                                </Typography>
                                <Typography variant="h1" color="textSecondary" component="p">
                                    {graphData[0]?.values_sum}
                                </Typography>
                                <Typography variant="h3" color="textSecondary" component="p">
                                    {props.unit}
                                </Typography>
                            </Grid>
                        }
                    </div>
                    :<NoData footer={t('buildings.show.analytics.graphics.no_data.footer')}/>   
                }
            </>
        }
        {props.type !== 'geomap' && !graphData &&
            <div className={classes.loading} >
                <img src={LoadingGif} alt="loading..." />
            </div>
        }
    </>
}

export default React.memo(ChartPreview);
