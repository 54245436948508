// React admin components
import { useTranslate } from "react-admin";

// MUI components
import { makeStyles } from "@material-ui/core/styles";

// Font Awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
import { faHotel } from "@fortawesome/pro-regular-svg-icons";
import { faHospital } from "@fortawesome/pro-regular-svg-icons";
import { faHouseMedical } from "@fortawesome/pro-regular-svg-icons";
import { faShop } from "@fortawesome/pro-regular-svg-icons";
import { faIndustryWindows } from "@fortawesome/pro-regular-svg-icons";

// Custom styles
const useStyles = makeStyles({
  typeChoice: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "80%",
    margin: "auto",
  },
  choosenType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "70px",
    height: "70px",
    borderRadius: "25px",
    margin: "auto 1em",
    padding: "1em",
    lineHeight: "0.2em",
    "&:hover": {
      backgroundColor: "#e896127f",
    },
  },
  choosenTypeOnClick: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "70px",
    height: "70px",
    borderRadius: "25px",
    margin: "auto 1em",
    padding: "1em",
    lineHeight: "0.2em",
    backgroundColor: "#E89712",
  },
  recordTypeCustomStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70px",
    height: "70px",
    border: "1px solid #000000",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px  #2e2e2e33",
    margin: "auto",
    padding: "1em",
    lineHeight: "0.2em",
    color: "#000000",
    backgroundColor: "#ffffff",
  },
  typeTextCustomStyle: {
    fontSize: "14px",
  }
});

const BuildingTypesInformations = (props) => {
  const classes = useStyles(props);
  const t = useTranslate();

  const buildingTypes = [
    {
      name: t("buildings.create.type.school"),
      icon: faSchool,
      type: "school",
    },
    {
      name: t("buildings.create.type.office"),
      icon: faBuilding,
      type: "office",
    },
    {
      name: t("buildings.create.type.hotel"),
      icon: faHotel,
      type: "hotel",
    },
    {
      name: t("buildings.create.type.hospital"),
      icon: faHospital,
      type: "hospital",
    },
    {
      name: t("buildings.create.type.pharmacy"),
      icon: faHouseMedical,
      type: "pharmacy",
    },
    {
      name: t("buildings.create.type.store"),
      icon: faShop,
      type: "store",
    },
    {
      name: t("buildings.create.type.industry"),
      icon: faIndustryWindows,
      type: "industry",
    },
  ];

  if (props.record) {
    const recordType = buildingTypes.filter((e) => e.type === props.record.type);

    return (
      <div className={classes.recordTypeCustomStyle}>
        <FontAwesomeIcon icon={recordType[0]?.icon} size="3x" />
        <p className={classes.typeTextCustomStyle}>{recordType[0]?.name}</p>
      </div>
    );
  } else {
    const handleTypeOnClick = (type) => {
      props.onChange(type.type !== props.value ? type.type : undefined);
    };


    return (
      <div>
        <div className={classes.typeChoice}>
          {buildingTypes.map((choice) => (
            <div
              key={choice.type}
              className={
                props.value === choice.type
                  ? classes.choosenTypeOnClick
                  : classes.choosenType
              }
              onClick={() => handleTypeOnClick(choice)}
            >
              <FontAwesomeIcon icon={choice.icon} size="2x" />
              <p className={classes.typeTextCustomStyle}>{choice.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default BuildingTypesInformations;
