// Libs
import {
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TextInput,
  useTranslate,
  FormDataConsumer,
} from "react-admin";

// React Final Form
import { useFormState, useForm } from "react-final-form";

// Components
import CustomAddButton from "../../../styles/CustomButtons/CustomAddButton";
import CustomRemoveButton from "../../../styles/CustomButtons/CustomRemoveButton";

const BuildingDevices = (props) => {
  const t = useTranslate();
  const formState = useFormState();
  const form = useForm();
  const devices_choises = [
    {
      id: "electricity_meter",
      name: t("buildings.create.meters.electricity_meter"),
    },
    {
      id: "gas_meter",
      name: t("buildings.create.meters.gas_meter"),
    },
    {
      id: "sensor",
      name: t("buildings.create.meters.sensor"),
    },
  ];

  const InputList = (type, source, rest) => {
    let parameters_array = source.match(/[^\]\[.]+/g);
    const properties = parameters_array?.reduce(
      (o, i) => o[i],
      formState.values
    );
    switch (type) {
      case "electricity_meter":
        // XXX: DIRTY FIX to handle difference between backend and front end format
        if (properties === undefined) {
          source = source + `[0]`;
          form.change(source + `name`, "pdl");
          source = source + "value";
        } else {
          const index = properties?.map((item) => item.name).indexOf("pdl");
          source = source + `[${index}]` + ".value";
        }
        rest.label = t("buildings.create.form_label.property_pdl");
        return <TextInput source={source} {...rest} />;
      case "gas_meter":
        if (properties === undefined) {
          source = source + `[0]`;
          form.change(source + `name`, "pce");
          source = source + "value";
        } else {
          const index = properties?.map((item) => item.name).indexOf("pce");
          source = source + `[${index}]` + ".value";
        }
        rest.label = t("buildings.create.form_label.property_pce");
        return <TextInput source={source} {...rest} />;
      case "sensor":
        if (properties === undefined) {
          source = source + `[0]`;
          form.change(source + `name`, "sensor");
          source = source + "value";
        } else {
          const index = properties?.map((item) => item.name).indexOf("sensor");
          source = source + `[${index}]` + ".value";
        }
        rest.label = t("buildings.create.form_label.property_sensor");
        return <TextInput source={source} {...rest} />;
      default:
        break;
    }
  };

  return (
    <div>
      <ArrayInput label={false} source="devices" defaultValue={props.devices}>
        <SimpleFormIterator addButton={<CustomAddButton props={props} />} removeButton={<CustomRemoveButton props={props} />}>
          <TextInput label={t("buildings.create.form_label.device_name")} source="name" />
          <SelectInput
            label={t("buildings.create.devices.type")}
            source="type"
            choices={devices_choises}
          />
          <FormDataConsumer>
            {({
              formData, // The whole form data
              scopedFormData, // The data for this item of the ArrayInput
              getSource, // A function to get the valid source inside an ArrayInput
              ...rest
            }) =>
              scopedFormData && scopedFormData.type
                ? InputList(scopedFormData.type, getSource("properties"), rest)
                : null
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

export default BuildingDevices;
