// Lib
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  useTranslate,
  useRecordContext,
} from "react-admin";

// Components
import DeviceListActions from "./CustomButtons/DeviceFilterButton";

// Display device type as words
const DeviceType = () => {
  const record = useRecordContext();
  const t = useTranslate();

  if (record.type === "electricity_meter") {
    return <span>{t("devices.name.electricity")}</span>;
  } else if (record.type === "gas_meter") {
    return <span>{t("devices.name.gas")}</span>;
  } else {
    return <span>{t("devices.name.undefined")}</span>;
  }
};

const DeviceList = (props) => {
  const t = useTranslate();

  return (
    <List
      {...props}
      actions={<DeviceListActions props={props} />}
      exporter={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <ReferenceField source="parent" reference="buildings">
          <TextField source="name" />
        </ReferenceField>
        <DeviceType label={t("devices.list.type")} />
      </Datagrid>
    </List>
  );
};

export default DeviceList;
