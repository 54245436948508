// Libs
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "react-admin";

// Components
import CustomSaveButton from "./CustomSaveButton";

// Multistepform custom design
const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: "#FAFAFA",
    justifyContent: "center",
  },
}));

const CustomRegistrationToolbar = (props) => {
  const classes = useStyles();

  return (
    <Toolbar classes={classes}>
      <CustomSaveButton props={props} />
    </Toolbar>
  );
};

export default CustomRegistrationToolbar;
