// Libs
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
// Components
import Switch from 'react-switch';

// Context

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignContent: "flex-end",
        flexFlow: "row"
    },
    switchWrapper: {
        verticalAlign: "middle",
        marginLeft: "4px"
    },
    switch: {
        verticalAlign: "middle",
        marginLeft: "4px"
    },
    textWrapper :  {
        padding: "0px 0px 5px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        marginLeft: "5px",
        fontFamily: theme.typography.fontFamily,
        fontSize: 14
    }
}));


const SwitchButton = props => {
    const t = useTranslate();
    const classes = useStyles();
    const [checked, setChecked] = useState(props.value)

    const onChangeChecked = value => {
        setChecked(value);
        props.onChange(value)
    }

    return (
        <div className={classes.container}>
            <div className={classes.switchWrapper}>
                <label htmlFor="enabled-switch">
                    <Switch
                        className={classes.switch}
                        onChange={onChangeChecked}
                        checked={checked}
                        onColor={"#e89712"}
                        height={19}
                        width={40}
                        handleDiameter={15}
                        disabled={props.disabled ? props.disabled : false}
                    />
                </label>
            </div>
            <div className={classes.textWrapper}>
                {checked === true ? t(props.onText) : t(props.offText)}
            </div>
        </div>
    );
};


export default SwitchButton;
