// Libs
import React from "react";
import { Create, SimpleForm, useTranslate } from "react-admin";

// Components
import CustomRegistrationToolbar from "../CustomToolbars/CustomRegistrationToolbar";
import UserCreateForm from "./UserCreateForm";

const UserCreate = (props) => {
  const t = useTranslate();

  return (
    <Create title={t("users.create.title")} {...props}>
      <SimpleForm toolbar={<CustomRegistrationToolbar />}>
        <UserCreateForm />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
