const translateDashboardsFr = {
  dashboards: {
    show: {
      title: "Afficher les dashboards",
      no_graphic: "Pas encore de Graphique.",
      create_one: "Voulez vous en créer un ?"
    },
    create: {
      title: "Ajouter un tableau de bord",
      general_informations: "Informations générales",
      name: "Nom",
      graph: "Créer",
    },
    chart_type: {
      chart_column_bar: "Barres en colonnes",
      chart_horizontal_bar: "Barres horizontales",
      chart_line: "En lignes",
      number: "Nombre",
      map: "Carte",
    },
    add_graph: "Ajouter un graphique",
    server_error: "Impossible de soumettre le formulaire",
    graphs: {
      aggregate_type: "Type d'aggregation",
      aggregate_period: "Période d'aggregation",
      graph_type: "Type de graphique",
      period_type: "Type de période",
      aggregate_operation: "Opération d'aggrégation",
      aggregate_category: "Catégorie d'aggrégration",
      aggregate_value: "Valeur",
      device: "Device",
      properties: "Propriété",
      title: "Titre",
      data_source: "Source des données",
      add_source: "+ Ajouter une source",
      period_types: {
        selected: "Période sélectionnée",
        weeks: "Dernière(s) semaine(s)",
      },
      aggregation: {
        period: {
          raw: "Raw",
          hour: "Heure",
          day: "Jour",
          week: "Semaine",
          month: "Mois",
          year: "Année",
        },
        type: {
          raw: "Raw",
          average: "Moyenne",
          sum: "Somme",
        },
        operation: {
          none: "/",
          weather: "Météo",
        },
        category: {
          none: "Aucune",
          arithmetic: "Arithmetic",
          functional: "Fonctionnelle",
        },
      },
    },
  },
};

export default translateDashboardsFr;
