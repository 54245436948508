import { makeStyles } from "@material-ui/core/styles";

const globalUseStyles = makeStyles((theme) => ({
  globalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down(980)]: {
      width: "95%",
      flexDirection: "column",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0.5rem",
    padding: "1rem",
    backgroundColor: "white",
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33",
  },
  cardLeftPart: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    justifyContent: "space-between",
  },
  cardRightPart: {
    display: "flex",
    flexDirection: "column",
    width: "65%",
    margin: "auto",
    justifyContent: "space-between",
  },
  insideCardRightPart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    borderRadius: "25px",
  },
  customTextField: {
    fontSize: "1.1em",
    color: "#000000",
    fontWeight: "bold",
    textAlign: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    padding: "0.5em",
    marginBottom: "1rem",
  },
  emptyCustomTextField: {
    fontSize: "1.1em",
    color: "#000000",
    textAlign: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px  #2e2e2e33",
    padding: "0.7em",
    marginBottom: "1rem",
  },
  customBooleanField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    padding: "0.5em",
    margin: "0.2rem 0 1rem",
  },
  customChipField: {
    fontSize: "1.1em",
    fontWeight: "bold",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    backgroundColor: "white",
    height: "45px",
    margin: "0.2rem 0 1rem",
  },
  usersInAlertCustomChipField: {
    fontSize: "1.1em !important",
    fontWeight: "bold !important",
    backgroundColor: "white !important",
    margin: 0,
  },
  customBoldTextField: {
    fontSize: "1.1em",
    fontWeight: "bold",
    color: "#000000",
  },
  customArrayField: {
    fontSize: "1.1em",
    color: "#000000",
    fontWeight: "bold",
    textAlign: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    marginBottom: "1rem",
    overflow: "hidden",
  },
  customReferenceArrayField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    padding: "0.5em",
    margin: "0.2rem 0 1rem",
  },
  customSpanField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "1.1em",
    fontWeight: "bold",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px  #2e2e2e33",
    padding: "0.7em 0.5em",
    margin: "0.2rem 0 1rem",
  },
  mapContainer: {
    border: "1px solid #2e2e2e33",
    borderRadius: "25px",
    overflow: "hidden",
    padding: "0",
  },
  customPhoneContainer: {
    marginBottom: "1.5em !important",
  },
  customPhoneField: {
    width: "100% !important",
    height: "2.7em !important",
    fontSize: "1.1em !important",
    color: "#000000 !important",
    fontWeight: "bold !important",
    textAlign: "center !important",
    border: "1px solid #000000 !important",
    borderRadius: "15px !important",
    boxShadow: "1px 3px 2px #2e2e2e33 !important",
    padding: "0.5em !important",
    "&:hover": {
      cursor: "text",
    },
  },
  customPhoneInput: {
    fontSize: "1.1em !important",
    width: "100% !important",
    height: "1.1876em !important",
    borderRadius: "0 !important",
    border: "none !important",
    boxShadow: "0 1px #8f8f8f !important",
    backgroundColor: "#f5f5f5 !important",
    paddingTop: "1.5em !important",
    paddingBottom: "1.5em !important",
    "&:hover": {
      backgroundColor: "#DEDEDE !important",
    },
    "&:focus": {
      boxShadow: "0 2px black !important",
      borderBottomHeight: "10px !important",
      backgroundColor: "#E8E8E8 !important",
      fontWeight: "bold !important",
      transition: "box-shadow 0.5s ease-in-out",
    },
  },
  buildingsFormContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    margin: "auto",
    backgroundColor: "white",
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    [theme.breakpoints.down(980)]: {
      width: "95%",
      flexDirection: "column",
    },
  },
  dashboards_ItemCard: {
    background: "white !important",
    border: "0.5px solid #2e2e2e33 !important",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33 !important",
  },
  dashboards_ItemContainer: {
    display: "flex",
    alignContent: "stretch",
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    "&:hover $ItemToolBar": {
      opacity: "1",
    },
  },
  dashboards_GraphContainer: {
    display: "flex",
    padding: "8px",
    flex: 1,
  },
  dashboards_ItemToolBar: {
    zIndex: "500",
    display: "flex",
    position: "absolute",
    left: "0px",
    top: "0px",
    opacity: "0.5",
    transition: "all ease 0.2s",
    "& button": {
      marginRight: "8px",
    },
  },
  dashboards_buttonBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dashboards_EmptyCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33 !important",
  },
}));

export default globalUseStyles;
