const translateDevicesFr = {
  devices: {
    title: {
      create: "Ajouter un équipement"
    },
    name: {
      electricity: "Compteur électrique",
      gas: "Compteur de gaz",
      undefined: "Non défini",
    },
    list: {
      name: "Nom",
      building: "Bâtiment",
      type: "Type",
      properties: "Propriétés",
    },
  },
};

export default translateDevicesFr;
